
For the mobile version, rather than the message being shown underneath the trips
it will be instead be shown at the top of the display 

The message will be initially collapsed to a single line within its container, 
but can be expanded with a click

This uses the vuetify v-expansion-panel to accomplish this ineteraction

<template>
    <v-expansion-panels v-model="panels" v-if="hasMessage">
        <v-expansion-panel expand v-model="panels">
            <v-expansion-panel-header>
                <div v-if="!expandByDefault" class="message-cutoff">
                    <div class="cutoff-content">
                        {{header}}
                    </div>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="text === header">
                <div class="message-content">
                    {{text}}
                </div>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else>
                <div v-for="message in text" :key="message.message" class="message-content">
                    <i class="fas fa-circle"></i>
                    {{message.message}}
                    <br />
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    export default {
        name: "MobileMessage",
        props: ["message", "emergency", "expandByDefault"],
        data() {
            return {
                panels: this.$props.emergency ? 0 : [],
                text: "",
                header: "",
                hasMessage: false
            }
        },
        created(){
            const mess = this.$props.message;
            let hasMessage = false;

            console.log("MESSAGE MOBILE", mess, mess.length);

            if(Array.isArray(mess)){
                if(mess.length === 1){
                    this.header = mess[0].message;
                    this.text = mess[0].message
                    hasMessage = true;
                }
                else if(mess.length > 1){
                    this.header = "There are " + mess.length + " notifications"
                    this.text = mess;
                    // this.text = mess[0].message;
                    console.log("MULTI-MESSAGE", mess[0].message);
                    hasMessage = true;
                }
            }
            else if(mess){
                this.header = mess.message;
                this.text = mess.message;
                hasMessage = !!mess.message;
            }

            this.hasMessage = hasMessage;

            if(this.hasMessage && this.$props.expandByDefault){
                this.panels = 0;
            }
        }
    }
</script>

<style scoped>
    .v-expansion-panel:not(.v-expansion-panel--active) .message-cutoff {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        transition: 0.3s;
        font-size: 1.1rem;
        padding: 0.6rem 0.4rem;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
        position: relative;
        background-color: #eee;
        border-radius: 0.5rem;
        border: 1px solid rgb(236 188 188);
    }

    .mobile .v-expansion-panel:not(.v-expansion-panel--active) .message-cutoff {
        margin-top: 0.5rem;
    }

    .v-expansion-panel::before {
        box-shadow: none;
    }

    .v-expansion-panel:not(.v-expansion-panel--active) .message-cutoff::before {
        content: "expand";
        position: absolute;
        font-size: 0.85rem;
        color: white;
        background-color: rgb(238 85 85); /*rgb(247, 85, 85);*/
        right: 0.25rem;
        top: 0;
        top: 50%;
        border-radius: 0.5rem;
        padding: 0.15rem 0.75rem;
        transform: translateY(-50%);
    }

    .v-expansion-panel:not(.v-expansion-panel--active)::after {
        position: absolute;
        content: "";
        left: calc(4vw - 0.75rem + 1px);
        top: 50%;
        transform: translateY(-50%);
        height: 0.75rem;
        width: 0.75rem;
        background-color: rgb(238 85 85); /*rgb(247, 85, 85);*/
        border-radius: 50%;
    }

    .smallscreen .v-expansion-panel:not(.v-expansion-panel--active)::after {
        height: 0;
    }

    .message-cutoff:hover {
        background-color: #ddd;
    }

    .v-expansion-panel--active .message-cutoff {
        color: transparent;
        /* background-color: rgba(0, 0, 0, 0.87); */
        border: 1px solid var(--primary-color);
        height: 2px;
        margin: 0.25rem 0;
    }

    .v-expansion-panel--active .message-cutoff::after {
        content: "close";
        position: absolute;
        top: 50%;
        right: calc(24px + 0.5rem);
        font-size: 0.8rem;
        transform: translate(-0%, -50%);
        color: black;
        padding: 0.18rem 0.75rem 0.02rem;
        z-index: 100;
        background-color: white;
        border: 1px solid var(--primary-color);
        border-radius: 0.5rem;
    }

    .cutoff-content {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: calc(100% - 4vw - 2.75rem - 1px);
        margin-left: calc(4vw - 1.25rem + 1px);
    }
    
    .smallscreen .cutoff-content {
        width: calc(100% - 4rem);
        margin-left: 0;
    }
    
</style>

<style>
    .v-expansion-panel-content__wrap {
        font-size: 1.25rem;
        padding: 0 4vw 16px !important;
        position: relative;
    }
    
    .message-content {
        position: relative;
        padding: 0.75rem;
        padding-left: 1rem;
        border: 1px solid #aaa;
        border-left: none;
        box-shadow: 3px 3px 5px rgba(0,0,0,0.4);
        margin-top: 0.25rem;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    .message-content::before {
        content: "";
        position: absolute;
        left: -10px;
        top: -1px;
        /* height: 1.25rem; */
        height: calc(100% + 2px);
        width: 0.75rem;
        background-color: rgb(238 85 85); /*rgb(247, 85, 85);*/
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }

    .v-expansion-panel-content__wrap div {
        /* text-align: center; */
        font-size: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .v-expansion-panel-content__wrap svg {
        transform: scale(0.5);
    }

    .mobile .v-expansion-panel-content__wrap svg {
        transform-origin: 0 0;
    }
</style>