
const RailStationLabels = {
        "AC":[
        {"line":"AC","name":"Absecon","coordinates":{"lat":39.42399,"lng":-74.50161}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"AC","name":"Atco","coordinates":{"lat":39.783594,"lng":-74.90779}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"AC", "start":"true","name":"Atlantic City Rail Terminal","coordinates":{"lat":39.362893,"lng":-74.440336}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"AC","name":"Cherry Hill","coordinates":{"lat":39.928452,"lng":-75.04176}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"AC","name":"Egg Harbor City","coordinates":{"lat":39.526426,"lng":-74.648064}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"AC","name":"Hammonton","coordinates":{"lat":39.631666,"lng":-74.799459}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"AC","name":"Lindenwold","coordinates":{"lat":39.834015,"lng":-75.00066}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"AC","name":"North Philadelphia","coordinates":{"lat":39.997186,"lng":-75.155252}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"AC","name":"Pennsauken","coordinates":{"lat":39.977992,"lng":-75.061928}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"AC","name":"Philadelphia","coordinates":{"lat":39.955779,"lng":-75.181968}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"}],
        "BC":[
        {"line":"BC","name":"Allendale","coordinates":{"lat":41.030871,"lng":-74.131176}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"BC","name":"Broadway Fair Lawn","coordinates":{"lat":40.922291,"lng":-74.115243}, translate: "1vmax, 0.5vmax", mobileTranslate: "2vmax, 1vmax", point: "TL"},
        {"line":"BC","name":"Campbell Hall","coordinates":{"lat":41.450601,"lng":-74.26574}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"BC","name":"Garfield","coordinates":{"lat":40.866659,"lng":-74.105652}, translate: "calc(-100% - 1vmax), 0.5vmax", mobileTranslate: "calc(-100% - 2vmax), 1vmax", point: "TR"},
        {"line":"BC","name":"Glen Rock Boro Hall","coordinates":{"lat":40.961363,"lng":-74.12944}, translate: "calc(-100% - 1vmax), calc(-100% - 0.5vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 1vmax)", point: "BR"},
        {"line":"BC","name":"Harriman","coordinates":{"lat":41.293273,"lng":-74.139891}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"BC","start":"true","name":"Hoboken","coordinates":{"lat":40.734976,"lng":-74.027591}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"BC","name":"Hohokus","coordinates":{"lat":40.997396,"lng":-74.113358}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"BC","name":"Mahwah","coordinates":{"lat":41.094176,"lng":-74.146748}, translate: "1vmax, calc(-100% - 0.5vmax)", mobileTranslate: "2vmax, calc(-100% - 1vmax)", point: "BL"},
        {"line":"BC","name":"Middletown NY","coordinates":{"lat":41.457481,"lng":-74.370389}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"BC","name":"Otisville","coordinates":{"lat":41.471814,"lng":-74.529216}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"BC","name":"Plauderville","coordinates":{"lat":40.884797,"lng":-74.10282}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"BC","name":"Port Jervis","coordinates":{"lat":41.374892,"lng":-74.694623}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"BC","name":"Radburn Fair Lawn","coordinates":{"lat":40.939537,"lng":-74.121559}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"BC","name":"Ramsey Main St","coordinates":{"lat":41.056846,"lng":-74.142216}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"BC","name":"Ramsey Route 17","coordinates":{"lat":41.07415,"lng":-74.144378}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"BC","name":"Ridgewood","coordinates":{"lat":40.981182,"lng":-74.120613}, translate: "1vmax, 0.5vmax", mobileTranslate: "2vmax, 1vmax", point: "TL"},
        {"line":"BC","name":"Rutherford","coordinates":{"lat":40.828275,"lng":-74.100806}, translate: "1vmax, 0.5vmax", mobileTranslate: "2vmax, 1vmax", point: "TL"},
        {"line":"BC","name":"Salisbury Mills-Cornwall","coordinates":{"lat":41.436656,"lng":-74.101674}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"BC","name":"Secaucus Lower Lvl","coordinates":{"lat":40.760947,"lng":-74.07539}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"BC","name":"Sloatsburg","coordinates":{"lat":41.157138,"lng":-74.191308}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"BC","name":"Suffern","coordinates":{"lat":41.11399,"lng":-74.153877}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"BC","name":"Tuxedo","coordinates":{"lat":41.19423,"lng":-74.184504}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"BC","name":"Waldwick","coordinates":{"lat":41.012121,"lng":-74.123082}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"BC","name":"Wesmont","coordinates":{"lat":40.855221,"lng":-74.096941}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"}],
        "GS":[
        {zoomLimit: true},
        {"line":"GS","name":"Basking Ridge","coordinates":{"lat":40.711369,"lng":-74.555386}, translate: "1vmax, calc(-100% - 1.25vmax)", mobileTranslate: "2vmax, calc(-100% - 2.5vmax)", point: "BL"},
        {"line":"GS","name":"Berkeley Heights","coordinates":{"lat":40.682433,"lng":-74.44272}, translate: "0.75vmax, 1.5vmax", mobileTranslate: "1.5vmax, 3vmax", point: "TL"},
        {"line":"GS","name":"Bernardsville","coordinates":{"lat":40.716947,"lng":-74.571156}, translate: "calc(-100% - 0.5vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 1vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"GS","name":"Brick Church","coordinates":{"lat":40.765552,"lng":-74.21925}, translate: "1vmax, calc(-100% - 0.5vmax)", mobileTranslate: "2vmax, calc(-100% - 1vmax)", point: "BL"},
        {"line":"GS","name":"East Orange","coordinates":{"lat":40.761348,"lng":-74.210981}, translate: "1vmax, -35%", mobileTranslate: "2vmax, -35%", point: "L"},
        {"line":"GS","name":"Far Hills","coordinates":{"lat":40.685561,"lng":-74.633801}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"GS","name":"Gillette","coordinates":{"lat":40.678246,"lng":-74.468062}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"GS","name":"Gladstone","coordinates":{"lat":40.720284,"lng":-74.666286}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"GS","name":"Highland Avenue","coordinates":{"lat":40.766844,"lng":-74.243614}, translate: "calc(-100% - 0.75vmax), calc(-100% - 1.25vmax)", mobileTranslate: "calc(-100% - 1.5vmax), calc(-100% - 2.5vmax)", point: "BR"},
        {"line":"GS","name":"Hoboken","coordinates":{"lat":40.734976,"lng":-74.027591}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"GS","name":"Lyons","coordinates":{"lat":40.684767,"lng":-74.549559}, translate: "calc(-100% - 1vmax), 0.5vmax", mobileTranslate: "calc(-100% - 2vmax), 1vmax", point: "TR"},
        {"line":"GS","name":"Maplewood","coordinates":{"lat":40.731059,"lng":-74.275339}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"GS","name":"Millburn","coordinates":{"lat":40.725733,"lng":-74.303699}, translate: "calc(-100% - 0.5vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 1vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"GS","name":"Millington","coordinates":{"lat":40.673616,"lng":-74.523489}, translate: "calc(-100% - 0.75vmax), 1.25vmax", mobileTranslate: "calc(-100% - 1.5vmax), 2.5vmax", point: "TR"},
        {"line":"GS","name":"Mountain Station","coordinates":{"lat":40.75536,"lng":-74.253024}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"GS","name":"Murray Hill","coordinates":{"lat":40.694945,"lng":-74.403186}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"GS","name":"New Providence","coordinates":{"lat":40.712119,"lng":-74.386395}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"GS","start":"true","name":"New York Penn Station","coordinates":{"lat":40.750569,"lng":-73.993519}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"GS","name":"Newark Broad Street","coordinates":{"lat":40.747446,"lng":-74.171922}, translate: "0.5vmax, 1vmax", mobileTranslate: "1vmax, 2vmax", point: "TL"},
        {"line":"GS","name":"Orange","coordinates":{"lat":40.771774,"lng":-74.233301}, translate: "0.75vmax, calc(-100% - 1.25vmax)", mobileTranslate: "1.5vmax, calc(-100% - 2.5vmax)", point: "BL"},
        {"line":"GS","name":"Peapack","coordinates":{"lat":40.708803,"lng":-74.658374}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"GS","name":"Secaucus Upper Lvl","coordinates":{"lat":40.761561,"lng":-74.075886}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"GS","name":"Short Hills","coordinates":{"lat":40.725155,"lng":-74.323791}, translate: "0.75vmax, 1.25vmax", mobileTranslate: "1.5vmax, 2.5vmax", point: "TL"},
        {"line":"GS","name":"South Orange","coordinates":{"lat":40.745894,"lng":-74.26043}, translate: "calc(-100% - 1vmax), calc(-100% - 0.5vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 1vmax)", point: "BR"},
        {"line":"GS","name":"Stirling","coordinates":{"lat":40.674716,"lng":-74.493406}, translate: "0.75vmax, 1.5vmax", mobileTranslate: "1.5vmax, 3vmax", point: "TL"},
        {"line":"GS","name":"Summit","coordinates":{"lat":40.716697,"lng":-74.357681}, translate: "calc(-100% - 0.75vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 1.5vmax), calc(-100% - 2vmax)", point: "BR"}],
        "MC":[
        {zoomLimit: true},
        {"line":"MC","name":"Bay Street","coordinates":{"lat":40.808196,"lng":-74.20872}, translate: "1.25vmax, calc(-100% - 0.5vmax)", mobileTranslate: "2.5vmax, calc(-100% - 1vmax)", point: "BL"},
        {"line":"MC","name":"Bloomfield","coordinates":{"lat":40.792772,"lng":-74.19995}, translate: "1.25vmax, -50%", mobileTranslate: "2.5vmax, -50%", point: "L"},
        {"line":"MC","name":"Boonton","coordinates":{"lat":40.903147,"lng":-74.408252}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"MC","name":"Denville","coordinates":{"lat":40.883423,"lng":-74.481839}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"MC","name":"Dover","coordinates":{"lat":40.883628,"lng":-74.555524}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"MC","name":"Glen Ridge","coordinates":{"lat":40.800345,"lng":-74.204725}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"MC","name":"Hackettstown","coordinates":{"lat":40.851953,"lng":-74.834709}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"MC","name":"Hoboken","coordinates":{"lat":40.734976,"lng":-74.027591}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"MC","name":"Lake Hopatcong","coordinates":{"lat":40.904186,"lng":-74.665572}, translate: "calc(-100% - 0.75vmax), calc(-100% - 1.5vmax)", mobileTranslate: "calc(-100% - 1.5vmax), calc(-100% - 3vmax)", point: "BR"},
        {"line":"MC","name":"Lincoln Park","coordinates":{"lat":40.924126,"lng":-74.302352}, translate: "1vmax, calc(-100% - 1.5vmax)", mobileTranslate: "2vmax, calc(-100% - 3vmax)", point: "BL"},
        {"line":"MC","name":"Little Falls","coordinates":{"lat":40.880638,"lng":-74.235243}, translate: "calc(-100% - 1.5vmax), -50%", mobileTranslate: "calc(-100% - 3vmax), -50%", point: "R"},
        {"line":"MC","name":"Montclair Heights","coordinates":{"lat":40.857619,"lng":-74.202581}, translate: "1.25vmax, -50%", mobileTranslate: "2.5vmax, -50%", point: "L"},
        {"line":"MC","name":"Montclair State University","coordinates":{"lat":40.869858,"lng":-74.197402}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"MC","name":"Mount Arlington","coordinates":{"lat":40.896505,"lng":-74.632791}, translate: "0.75vmax, calc(-100% - 1.25vmax)", mobileTranslate: "1.5vmax, calc(-100% - 2.5vmax)", point: "BL"},
        {"line":"MC","name":"Mount Olive","coordinates":{"lat":40.907424,"lng":-74.730776}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"MC","name":"Mountain Avenue","coordinates":{"lat":40.84884,"lng":-74.205465}, translate: "calc(-100% - 1.25vmax), calc(-100% - 0.5vmax)", mobileTranslate: "calc(-100% - 2.5vmax), calc(-100% - 1vmax)", point: "BR"},
        {"line":"MC","name":"Mountain Lakes","coordinates":{"lat":40.885861,"lng":-74.433644}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"MC","name":"Mountain View","coordinates":{"lat":40.913946,"lng":-74.267754}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"MC","name":"Netcong","coordinates":{"lat":40.897692,"lng":-74.70736}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"MC","start":"true","name":"New York Penn Station","coordinates":{"lat":40.750569,"lng":-73.993519}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"MC","name":"Newark Broad Street","coordinates":{"lat":40.747446,"lng":-74.171922}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"MC","name":"Secaucus Upper Lvl","coordinates":{"lat":40.761561,"lng":-74.075886}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"MC","name":"Towaco","coordinates":{"lat":40.923231,"lng":-74.343625}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"MC","name":"Upper Montclair","coordinates":{"lat":40.842038,"lng":-74.209482}, translate: "calc(-100% - 1.5vmax), -50%", mobileTranslate: "calc(-100% - 3vmax), -50%", point: "R"},
        {"line":"MC","name":"Walnut Street","coordinates":{"lat":40.817262,"lng":-74.209796}, translate: "calc(-100% - 1.5vmax), -50%", mobileTranslate: "calc(-100% - 3vmax), -50%", point: "R"},
        {"line":"MC","name":"Watchung Avenue","coordinates":{"lat":40.829663,"lng":-74.207023}, translate: "1.25vmax, calc(-100% - 0.5vmax)", mobileTranslate: "2.5vmax, calc(-100% - 1vmax)", point: "BL"},
        {"line":"MC","name":"Watsessing Avenue","coordinates":{"lat":40.782764,"lng":-74.198439}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"MC","name":"Wayne-Route 23","coordinates":{"lat":40.899813,"lng":-74.256622}, translate: "1.25vmax, calc(-100% - 0.75vmax)", mobileTranslate: "2.5vmax, calc(-100% - 1.5vmax)", point: "BL"}],
        "ME":[
        {zoomLimit: true},
        {"line":"ME","name":"Brick Church","coordinates":{"lat":40.765552,"lng":-74.21925}, translate: "1vmax, calc(-100% - 0.5vmax)", mobileTranslate: "2vmax, calc(-100% - 1vmax)", point: "BL"},
        {"line":"ME","name":"Chatham","coordinates":{"lat":40.740085,"lng":-74.385107}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ME","name":"Convent Station","coordinates":{"lat":40.778914,"lng":-74.443463}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ME","name":"Denville","coordinates":{"lat":40.883423,"lng":-74.481839}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"ME","name":"Dover","coordinates":{"lat":40.883628,"lng":-74.555524}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ME","name":"East Orange","coordinates":{"lat":40.761348,"lng":-74.210981}, translate: "1vmax, -35%", mobileTranslate: "2vmax, -35%", point: "L"},
        {"line":"ME","name":"Hackettstown","coordinates":{"lat":40.851953,"lng":-74.834709}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"ME","name":"Highland Avenue","coordinates":{"lat":40.766844,"lng":-74.243614}, translate: "calc(-100% - 0.75vmax), calc(-100% - 1.25vmax)", mobileTranslate: "calc(-100% - 1.5vmax), calc(-100% - 2.5vmax)", point: "BR"},
        {"line":"ME","name":"Hoboken","coordinates":{"lat":40.734976,"lng":-74.027591}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"ME","name":"Lake Hopatcong","coordinates":{"lat":40.904186,"lng":-74.665572}, translate: "calc(-100% - 0.75vmax), calc(-100% - 1.5vmax)", mobileTranslate: "calc(-100% - 1.5vmax), calc(-100% - 3vmax)", point: "BR"},
        {"line":"ME","name":"Madison","coordinates":{"lat":40.756998,"lng":-74.415376}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ME","name":"Maplewood","coordinates":{"lat":40.731059,"lng":-74.275339}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"ME","name":"Millburn","coordinates":{"lat":40.725733,"lng":-74.303699}, translate: "calc(-100% - 0.5vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 1vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"ME","name":"Morris Plains","coordinates":{"lat":40.828573,"lng":-74.478353}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ME","name":"Morristown","coordinates":{"lat":40.79717,"lng":-74.474359}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ME","name":"Mount Arlington","coordinates":{"lat":40.896505,"lng":-74.632791}, translate: "0.75vmax, calc(-100% - 1.25vmax)", mobileTranslate: "1.5vmax, calc(-100% - 2.5vmax)", point: "BL"},
        {"line":"ME","name":"Mount Olive","coordinates":{"lat":40.907424,"lng":-74.730776}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ME","name":"Mount Tabor","coordinates":{"lat":40.875859,"lng":-74.481857}, translate: "2vmax, -50%", mobileTranslate: "4vmax, -50%", point: "L"},
        {"line":"ME","name":"Mountain Station","coordinates":{"lat":40.75536,"lng":-74.253024}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"ME","name":"Netcong","coordinates":{"lat":40.897692,"lng":-74.70736}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"ME","start":"true","name":"New York Penn Station","coordinates":{"lat":40.750569,"lng":-73.993519}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"ME","name":"Newark Broad Street","coordinates":{"lat":40.747446,"lng":-74.171922}, translate: "0.5vmax, 1vmax", mobileTranslate: "1vmax, 2vmax", point: "TL"},
        {"line":"ME","name":"Orange","coordinates":{"lat":40.771774,"lng":-74.233301}, translate: "0.75vmax, calc(-100% - 1.25vmax)", mobileTranslate: "1.5vmax, calc(-100% - 2.5vmax)", point: "BL"},
        {"line":"ME","name":"Secaucus Upper Lvl","coordinates":{"lat":40.761561,"lng":-74.075886}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"ME","name":"Short Hills","coordinates":{"lat":40.725155,"lng":-74.323791}, translate: "0.75vmax, 1.25vmax", mobileTranslate: "1.5vmax, 2.5vmax", point: "TL"},
        {"line":"ME","name":"South Orange","coordinates":{"lat":40.745894,"lng":-74.26043}, translate: "calc(-100% - 1vmax), calc(-100% - 0.5vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 1vmax)", point: "BR"},
        {"line":"ME","name":"Summit","coordinates":{"lat":40.716697,"lng":-74.357681}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"}],
        "ML":[
        {"line":"ML","name":"Allendale","coordinates":{"lat":41.030871,"lng":-74.131176}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"ML","name":"Campbell Hall","coordinates":{"lat":41.450601,"lng":-74.26574}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"ML","name":"Clifton","coordinates":{"lat":40.867888,"lng":-74.153396}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"ML","name":"Delawanna","coordinates":{"lat":40.831824,"lng":-74.131497}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"ML","name":"Glen Rock Main Line","coordinates":{"lat":40.962268,"lng":-74.133708}, translate: "calc(-100% - 1vmax), calc(-100% - 0.5vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 1vmax)", point: "BR"},
        {"line":"ML","name":"Harriman","coordinates":{"lat":41.293273,"lng":-74.139891}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"ML","name":"Hawthorne","coordinates":{"lat":40.942682,"lng":-74.15247}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"ML","start":"true","name":"Hoboken","coordinates":{"lat":40.734976,"lng":-74.027591}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"ML","name":"Hohokus","coordinates":{"lat":40.997396,"lng":-74.113358}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"ML","name":"Kingsland","coordinates":{"lat":40.810132,"lng":-74.11729}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"ML","name":"Lyndhurst","coordinates":{"lat":40.816353,"lng":-74.124247}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ML","name":"Mahwah","coordinates":{"lat":41.094176,"lng":-74.146748}, translate: "1vmax, calc(-100% - 0.5vmax)", mobileTranslate: "2vmax, calc(-100% - 1vmax)", point: "BL"},
        {"line":"ML","name":"Middletown NY","coordinates":{"lat":41.457481,"lng":-74.370389}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ML","name":"Otisville","coordinates":{"lat":41.471814,"lng":-74.529216}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"ML","name":"Passaic","coordinates":{"lat":40.849438,"lng":-74.133801}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"ML","name":"Paterson","coordinates":{"lat":40.914952,"lng":-74.167315}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"ML","name":"Port Jervis","coordinates":{"lat":41.374892,"lng":-74.694623}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"ML","name":"Ramsey Main St","coordinates":{"lat":41.056846,"lng":-74.142216}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"ML","name":"Ramsey Route 17","coordinates":{"lat":41.07415,"lng":-74.144378}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"ML","name":"Ridgewood","coordinates":{"lat":40.981182,"lng":-74.120613}, translate: "1vmax, 0.5vmax", mobileTranslate: "2vmax, 1vmax", point: "TL"},
        {"line":"ML","name":"Salisbury Mills-Cornwall","coordinates":{"lat":41.436656,"lng":-74.101674}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"ML","name":"Secaucus Lower Lvl","coordinates":{"lat":40.760947,"lng":-74.07539}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"ML","name":"Sloatsburg","coordinates":{"lat":41.157138,"lng":-74.191308}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"ML","name":"Suffern","coordinates":{"lat":41.11399,"lng":-74.153877}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"ML","name":"Tuxedo","coordinates":{"lat":41.19423,"lng":-74.184504}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"ML","name":"Waldwick","coordinates":{"lat":41.012121,"lng":-74.123082}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"}],
        "NC":[
        {"line":"NC","name":"Aberdeen-Matawan","coordinates":{"lat":40.419734,"lng":-74.222014}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"NC","name":"Allenhurst","coordinates":{"lat":40.237588,"lng":-74.006557}, translate: "1.5vmax, 0.5vmax", mobileTranslate: "3vmax, 1vmax", point: "TL"},
        {"line":"NC","name":"Asbury Park","coordinates":{"lat":40.21629,"lng":-74.014543}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"NC","name":"Avenel","coordinates":{"lat":40.577692,"lng":-74.277421}, translate: "calc(-100% - 2vmax), -50%", mobileTranslate: "calc(-100% - 4vmax), -50%", point: "R"},
        {"line":"NC","name":"Bay Head","coordinates":{"lat":40.077225,"lng":-74.046091}, translate: "1.5vmax, 1vmax", mobileTranslate: "3vmax, 2vmax", point: "TL"},
        {"line":"NC","name":"Belmar","coordinates":{"lat":40.180317,"lng":-74.027473}, translate: "calc(-100% - 2vmax), -50%", mobileTranslate: "calc(-100% - 4vmax), -50%", point: "R"},
        {"line":"NC","name":"Bradley Beach","coordinates":{"lat":40.203734,"lng":-74.018706}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NC","name":"Elberon","coordinates":{"lat":40.265264,"lng":-73.997416}, translate: "2vmax, -50%", mobileTranslate: "4vmax, -50%", point: "L"},
        {"line":"NC","name":"Elizabeth","coordinates":{"lat":40.666781,"lng":-74.215757}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NC","name":"Hazlet","coordinates":{"lat":40.415295,"lng":-74.190281}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"NC","name":"Linden","coordinates":{"lat":40.629772,"lng":-74.250908}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NC","name":"Little Silver","coordinates":{"lat":40.326671,"lng":-74.040589}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"NC","name":"Long Branch","coordinates":{"lat":40.297113,"lng":-73.988301}, translate: "2vmax, -50%", mobileTranslate: "4vmax, -50%", point: "L"},
        {"line":"NC","name":"Manasquan","coordinates":{"lat":40.120759,"lng":-74.047796}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"NC","name":"Middletown NJ","coordinates":{"lat":40.390798,"lng":-74.11695}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"NC","name":"Monmouth Park","coordinates":{"lat":40.313425,"lng":-74.014921}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"NC","start":"true","name":"New York Penn Station","coordinates":{"lat":40.750569,"lng":-73.993519}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"NC","name":"Newark Airport","coordinates":{"lat":40.704368,"lng":-74.190679}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NC","name":"Newark Penn Station","coordinates":{"lat":40.734697,"lng":-74.164466}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NC","name":"North Elizabeth","coordinates":{"lat":40.680673,"lng":-74.205553}, translate: "calc(-100% - 2vmax), -50%", mobileTranslate: "calc(-100% - 4vmax), -50%", point: "R"},
        {"line":"NC","name":"Perth Amboy","coordinates":{"lat":40.509325,"lng":-74.273567}, translate: "2vmax, -50%", mobileTranslate: "4vmax, -50%", point: "L"},
        {"line":"NC","name":"Point Pleasant Beach","coordinates":{"lat":40.092848,"lng":-74.04806}, translate: "calc(-100% - 2vmax), -50%", mobileTranslate: "calc(-100% - 4vmax), -50%", point: "R"},
        {"line":"NC","name":"Rahway","coordinates":{"lat":40.606141,"lng":-74.27652}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"NC","name":"Red Bank","coordinates":{"lat":40.34778,"lng":-74.07393}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"NC","name":"Secaucus Upper Lvl","coordinates":{"lat":40.761561,"lng":-74.075886}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"NC","name":"South Amboy","coordinates":{"lat":40.484792,"lng":-74.280511}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"NC","name":"Spring Lake","coordinates":{"lat":40.150509,"lng":-74.035293}, translate: "1.5vmax, 0.5vmax", mobileTranslate: "3vmax, 1vmax", point: "TL"},
        {"line":"NC","name":"Woodbridge","coordinates":{"lat":40.556548,"lng":-74.27781}, translate: "2vmax, -50%", mobileTranslate: "4vmax, -50%", point: "L"}],
        "NE":[
        {"line":"NE","name":"Edison","coordinates":{"lat":40.519096,"lng":-74.410808}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NE","name":"Elizabeth","coordinates":{"lat":40.666781,"lng":-74.215757}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"NE","name":"Hamilton","coordinates":{"lat":40.255225,"lng":-74.703857}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NE","name":"Jersey Avenue","coordinates":{"lat":40.476473,"lng":-74.467932}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NE","name":"Linden","coordinates":{"lat":40.629772,"lng":-74.250908}, translate: "1vmax, 0.5vmax", mobileTranslate: "2vmax, 1vmax", point: "TL"},
        {"line":"NE","name":"Metropark","coordinates":{"lat":40.568095,"lng":-74.329633}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NE","name":"Metuchen","coordinates":{"lat":40.540698,"lng":-74.360341}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"NE","name":"New Brunswick","coordinates":{"lat":40.496648,"lng":-74.446092}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"NE","start":"true","name":"New York Penn Station","coordinates":{"lat":40.750569,"lng":-73.993519}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NE","name":"Newark Airport","coordinates":{"lat":40.704368,"lng":-74.190679}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"NE","name":"Newark Penn Station","coordinates":{"lat":40.734697,"lng":-74.164466}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"NE","name":"North Elizabeth","coordinates":{"lat":40.680673,"lng":-74.205553}, translate: "1vmax, 0.5vmax", mobileTranslate: "2vmax, 1vmax", point: "TL"},
        {"line":"NE","name":"Princeton Junction","coordinates":{"lat":40.315821,"lng":-74.624064}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"NE","name":"Rahway","coordinates":{"lat":40.606141,"lng":-74.27652}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"NE","name":"Secaucus Upper Lvl","coordinates":{"lat":40.761561,"lng":-74.075886}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"NE","name":"Trenton","coordinates":{"lat":40.218805,"lng":-74.754301}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"}],
        "PR":[
        {"line":"PR","name":"Princeton","coordinates":{"lat":40.34209,"lng":-74.65887}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"PR","start":"true","name":"Princeton Junction","coordinates":{"lat":40.315821,"lng":-74.624064}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"}],
        "PV":[
        {"line":"PV","name":"Anderson Street","coordinates":{"lat":40.89431,"lng":-74.044016}, translate: "calc(-100% - 1vmax), calc(-100% - 0.5vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 1vmax)", point: "BR"},
        {"line":"PV","name":"Emerson","coordinates":{"lat":40.975086,"lng":-74.027346}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"PV","name":"Essex Street","coordinates":{"lat":40.87928,"lng":-74.05184}, translate: "1vmax, 0.5vmax", mobileTranslate: "2vmax, 1vmax", point: "TL"},
        {"line":"PV","name":"Hillsdale","coordinates":{"lat":41.002436,"lng":-74.040912}, translate: "calc(-100% - 1vmax), 0.5vmax", mobileTranslate: "calc(-100% - 2vmax), 1vmax", point: "TR"},
        {"line":"PV","start":"true","name":"Hoboken","coordinates":{"lat":40.734976,"lng":-74.027591}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"PV","name":"Montvale","coordinates":{"lat":41.040533,"lng":-74.029277}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"PV","name":"Nanuet","coordinates":{"lat":41.090268,"lng":-74.01482}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"PV","name":"New Bridge Landing","coordinates":{"lat":40.910961,"lng":-74.035096}, translate: "1vmax, 0.5vmax", mobileTranslate: "2vmax, 1vmax", point: "TL"},
        {"line":"PV","name":"Oradell","coordinates":{"lat":40.953807,"lng":-74.030198}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"PV","name":"Park Ridge","coordinates":{"lat":41.032931,"lng":-74.035774}, translate: "calc(-100% - 1vmax), calc(-100% - 0.5vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 1vmax)", point: "BR"},
        {"line":"PV","name":"Pearl River","coordinates":{"lat":41.058553,"lng":-74.022265}, translate: "1vmax, calc(-100% - 0.5vmax)", mobileTranslate: "2vmax, calc(-100% - 1vmax)", point: "BL"},
        {"line":"PV","name":"River Edge","coordinates":{"lat":40.935171,"lng":-74.029259}, translate: "1vmax, -50%", mobileTranslate: "2vmax, -50%", point: "L"},
        {"line":"PV","name":"Secaucus Lower Lvl","coordinates":{"lat":40.760947,"lng":-74.07539}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"PV","name":"Spring Valley","coordinates":{"lat":41.111879,"lng":-74.044031}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"PV","name":"Teterboro","coordinates":{"lat":40.864857,"lng":-74.062677}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"},
        {"line":"PV","name":"Westwood","coordinates":{"lat":40.990766,"lng":-74.032904}, translate: "1vmax, calc(-100% - 0.5vmax)", mobileTranslate: "2vmax, calc(-100% - 1vmax)", point: "BL"},
        {"line":"PV","name":"Wood Ridge","coordinates":{"lat":40.843689,"lng":-74.078855}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"PV","name":"Woodcliff Lake","coordinates":{"lat":41.02109,"lng":-74.040847}, translate: "calc(-100% - 1vmax), -50%", mobileTranslate: "calc(-100% - 2vmax), -50%", point: "R"}],
        "RV":[
        {zoomLimit: true},
        {"line":"RV","name":"Annandale","coordinates":{"lat":40.645131,"lng":-74.878889}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"RV","name":"Bound Brook","coordinates":{"lat":40.56109,"lng":-74.530304}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"RV","name":"Bridgewater","coordinates":{"lat":40.559814,"lng":-74.55171}, translate: "calc(-100% - 0.5vmax), 1vmax", mobileTranslate: "calc(-100% - 1vmax), 2vmax", point: "TR"},
        {"line":"RV","name":"Cranford","coordinates":{"lat":40.655277,"lng":-74.302788}, translate: "calc(-100% - 0.5vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 1vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"RV","name":"Dunellen","coordinates":{"lat":40.590749,"lng":-74.462946}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"RV","name":"Fanwood","coordinates":{"lat":40.640832,"lng":-74.385135}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"RV","name":"Finderne","coordinates":{"lat":41.0222,"lng":-74.04065}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"RV","name":"Garwood","coordinates":{"lat":40.652606,"lng":-74.324944}, translate: "0.75vmax, 1vmax", mobileTranslate: "1.5vmax, 2vmax", point: "TL"},
        {"line":"RV","name":"High Bridge","coordinates":{"lat":40.666736,"lng":-74.895946}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"RV","name":"Hoboken","coordinates":{"lat":40.734976,"lng":-74.027591}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"RV","name":"Lebanon","coordinates":{"lat":40.636847,"lng":-74.836067}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"RV","name":"Netherwood","coordinates":{"lat":40.629072,"lng":-74.403351}, translate: "calc(-100% - 1vmax), calc(-100% - 0.5vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 1vmax)", point: "BR"},
        {"line":"RV","start":"true","name":"New York Penn Station","coordinates":{"lat":40.750569,"lng":-73.993519}, translate: "2vmax, -50%", mobileTranslate: "4vmax, -50%", point: "L"},
        {"line":"RV","name":"Newark Penn Station","coordinates":{"lat":40.734697,"lng":-74.164466}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"RV","name":"North Branch","coordinates":{"lat":40.592497,"lng":-74.684339}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"RV","name":"Plainfield","coordinates":{"lat":40.618406,"lng":-74.420414}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"RV","name":"Raritan","coordinates":{"lat":40.570709,"lng":-74.634396}, translate: "calc(-100% - 1vmax), 1vmax", mobileTranslate: "calc(-100% - 2vmax), 2vmax", point: "TR"},
        {"line":"RV","name":"Roselle Park","coordinates":{"lat":40.667166,"lng":-74.266599}, translate: "1vmax, 1vmax", mobileTranslate: "2vmax, 2vmax", point: "TL"},
        {"line":"RV","name":"Secaucus Upper Lvl","coordinates":{"lat":40.761561,"lng":-74.075886}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"RV","name":"Somerville","coordinates":{"lat":40.566075,"lng":-74.613971}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"},
        {"line":"RV","name":"Union","coordinates":{"lat":40.683326,"lng":-74.238546}, translate: "calc(-100% - 1vmax), calc(-100% - 1vmax)", mobileTranslate: "calc(-100% - 2vmax), calc(-100% - 2vmax)", point: "BR"},
        {"line":"RV","name":"Westfield","coordinates":{"lat":40.649273,"lng":-74.347639}, translate: "calc(-100% - 1vmax), -75%", mobileTranslate: "calc(-100% - 2vmax), -75%", point: "R"},
        {"line":"RV","name":"White House","coordinates":{"lat":40.615505,"lng":-74.770737}, translate: "1vmax, calc(-100% - 1vmax)", mobileTranslate: "2vmax, calc(-100% - 2vmax)", point: "BL"}]
    };

export default RailStationLabels;