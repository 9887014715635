

import getNewToken, { onMessageListener } from "./firebase";

const BASE_STORAGE_KEY = "__STORE_SUBS__";

let firebaseToken = null;

const UA = navigator.userAgent;
console.log(UA);
const IOS = UA.match(/iPhone|iPad|iPod/);
const ANDROID = UA.match(/Android/);
const MAC = UA.match(/Mac/);
const PLATFORM = IOS ? 'ios' : ANDROID ? 'android' : MAC ? 'mac' : 'unknown';

console.log("PLATFORM", PLATFORM);

const media = window.matchMedia('(display-mode: standalone)').matches;
const nav = navigator.standalone;
const andref = document.referrer.includes('android-app://');

const PWA = media || nav || andref;

console.log("IS PWA?", PWA);

const noSub = !PWA && IOS;

//
//
function handleNoAlertsMode(){
    if(noSub){
        const display = createModalBox();

        const first = document.createElement("p");
        first.innerHTML = "In order to use alerts on your iOS device, you must download and install this page as a web app on your home screen.";
        display.appendChild(first);

        display.appendChild(pwaSVG());

        const second = document.createElement("p");
        second.innerHTML = 'Look for the share icon in your browser, then tap “Add to Home Screen” to start.';
        display.appendChild(second);

        document.body.append(display);

        return true;
    }
    return false;
}

//
//
function getAllSubsFromLocalStorage(){
    const subs = localStorage.getItem(BASE_STORAGE_KEY);
    if(subs && subs.length){
        return JSON.parse(subs);
    }

    return {};
}

//
//
function setTopicSubsToLocalStorage(subs){
    localStorage.setItem(BASE_STORAGE_KEY, JSON.stringify(subs));
}

//
//
function clearOldSubs(){
    const subs = getAllSubsFromLocalStorage();

    const now = Date.now();

    Object.keys(subs).forEach(k => {
        const parts = k.split("_-_");
        console.log("SUB DIFF PARTS", parts);
        if(parts.length > 2){
            const timeString = parts[2].replaceAll("__", " ").replaceAll("_C_", ":");
            const d = new Date(timeString);

            console.log("SUB DIFF SPLITS", d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());

            // Date.UTC(year, monthIndex, day, hour, minute, second)
            const utc = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());

            const timespan = now - utc;

            console.log("SUB DIFF", timeString, now, d, timespan, utc, now-utc, 7200000 - timespan);

            if(timespan > 7200000) {
                // if the scheduled time was more than 100 minutes ago, remove the sub
                console.log("SUB DIFF ABOVE THRESHOLD", k, timespan);
                delete subs[k];
            }
        }
    });

    setTopicSubsToLocalStorage(subs);
}

//
//
onMessageListener().then(payload => {
    console.log("message listener", payload, this.title, this.content);
    this.title = payload.notification.title;
    this.content = payload.notification.body;

    if(PWA){
        alert(payload.notification.title + "   " + payload.notification.body);
    }
}).catch(err => console.log('failed: ', err));

//
//
function subscribe(topic, callback){
    console.log("requesting push permission");

    if(window.Notification){
        window.Notification.requestPermission()
        .then(permission => {
            if(permission === "granted"){
                console.log("Permission to notify granted");
        
                // getNewToken({vapidKey: "BBGX0pt8p0wLQzarwPq-H5-vLi1sh-OPUKeMluagPrJ6jrnF1xlozyDJmi61dtKjnrWQdYwUJ7g7NxQSXsCA4-I"})
                getNewToken({vapidKey: "BPnKptaf8-AGMAuxBsvKu5NEUZaYZ0FCz1CcOf0YuQyDzXCMfrJHJl5XrOM_15nwbpYtwS0fizV-toH_IA3aSLU"})
                .then(token => {
                    console.log("DO WE GET A TOKEN RESPONSE?", token);
                    if(token){

                        firebaseToken = token;

                        callAWSAPI(token, "", topic, "sub")
                        .then(sub => {
                            console.log("DO WE HAVE A SUB?", sub);

                            const subs = getAllSubsFromLocalStorage();
                            subs[topic] = sub;
                            setTopicSubsToLocalStorage(subs);

                            callback(token, sub, topic);
                        })
                    }
                });
            }
            else{
                console.log("Permission to notify denied");
            }
        });
    }
}

//
//
function unsubscribe(topic, sub, callback){
    callAWSAPI(firebaseToken, sub, topic, "unsub")
    .then(res => {
        console.log("UNSUB res", res);

        const subs = getAllSubsFromLocalStorage();
        delete subs[topic];

        console.log("UNSUBSCRIBE", topic, subs);

        setTopicSubsToLocalStorage(subs);
        
        callback(true);
    });
}

//
//
function checkIfAlertIsSet(stop, trip, station){
    // true/false for whether this stop is in the list

    console.log("CHECK ALERT PARAMS", stop, trip, station);

    const subs = getAllSubsFromLocalStorage();

    if(station) {
        console.log("CHECKING FOR THE STATION ALERT", station);
        const name = createTopicNameFromStation(station);

        console.log("STATION NAME TO CHECK", name, subs);

        if(subs[name]){
            return {topic: name, arn: subs[name]};
        }
        return null;
    }
    else{
        const name = createTopicNameFromStop(stop, trip);

        console.log("CHECKING STOP ALERT", name, subs);

        let found = false;

        Object.keys(subs).forEach(k => {
            if(k.indexOf(name) === 0){
                found = true;
                return;
            }
            else if(trainStopIDMatch(name, k)){
                const date1 = getDateTimeFromStopTopic(name);
                const date2 = getDateTimeFromStopTopic(k);

                const diff = Math.abs(date1 - date2);

                console.log("STOP TOPIC DIFF", diff);

                // within 6 hours
                if(diff < (1000*60*60*6)){
                    found = true;
                    return;
                }
            }
        });

        return found;
    }
}


function trainStopIDMatch(topicname, storedname){
    // parse off the stop and ID portion
    const split1 = topicname.split("_-_");
    const split2 = storedname.split("_-_");

    console.log("STATIONNAME SPLITS", split1, split2);

    if(split1[0] === split2[0] && split1[1] === split2[1]){
        return true;
    }

    return false;
}

// __New__York__Penn__Station_-_3240_-_31-May-2024__04_C_34_C_07__PM_-_5

function getDateTimeFromStopTopic(topicname){
    // parse off the date time portion
    const split = topicname.split("_-_");

    const timestring = split[2].replaceAll("__", " ").replaceAll("_C_", ":");

    console.log("TIME STRING", timestring);

    return new Date(timestring);
}

/*
<div style={{display: "flex", flexDirection: "column", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", padding: "1rem", backgroundColor: "white", border: "2px solid black"}}>
Select Interval:
<button onClick={() => confirmAlert(5)}>5 minutes</button>
<button onClick={() => confirmAlert(10)}>10 minutes</button>
<button onClick={() => confirmAlert(15)}>15 minutes</button>
</div>
*/

function runIntervalConfirmation(stop, trip, callback, isMobile){
    // create the above simple widget and display it, then just run the callback to Vue on click
    // the param for the callback will be the full topic name with interval included

    if(handleNoAlertsMode()){
        return;
    }

    console.log("RUN INTERVAL", stop, trip);

    const now = Date.now();
    const sched = new Date(stop.SchedDepTime);
    let diffMins = (sched - now)/60000;

    const display = createModalBox(isMobile);
    
    const title = document.createElement("h2");
    title.style = "margin-bottom: 0.5rem; margin-top: 1rem;";
    title.innerText = "Select Alert Timing:";
    display.appendChild(title);

    const run = (interval) => {
        // remove the interval modal element first
        display.remove();

        // then make the full topic name
        const topicName = createTopicNameFromStop(stop, trip, interval);

        // then hand that back to Vue
        callback(topicName);
    }

    for(let i = 5; i <= 15; i += 5){
        const button = document.createElement("button");
        button.innerText = `${i} minutes before`;

        if(diffMins < i){
            button.disabled = true;
            button.style = "width: 95%; background-color: #ccc; opacity: 0.5; padding: 0.5rem; margin: 0.75rem 0.5rem; border: 1px solid black; border-radius: 0.25rem;";
        }
        else{
            button.style = "width:95%; padding: 0.5rem; margin: 0.75rem 0.5rem; border: 1px solid black; border-radius: 0.25rem;";
        }

        button.addEventListener("click", () => run(i));

        display.appendChild(button);
    }

    document.body.appendChild(display);
}

//
//
function createModalBox(isMobile){
    const display = document.createElement("div");
    display.style = `position: relative; display: flex; flex-direction: column; align-items: center; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 1rem; background-color: white; border: 2px solid black; border-radius: 0.5rem; box-shadow: 0 0 1rem black; ${isMobile ? '' : '    width: 20rem;'}`;

    createXButton(display);

    return display;
}

//
//
function createXButton(parent){
    const x = document.createElement("button");
    x.style = "position: absolute; top: 0; right: 0; background-color: black; color: white; width: 1.5rem; height: 1.5rem; border-bottom-left-radius: 0.5rem;";
    x.innerText = "X";

    x.addEventListener("click", () => {
        parent.remove();
    })

    parent.appendChild(x);
}

//
//
function stationMessageSubscribe(station, callback){

    if(handleNoAlertsMode()){
        return;
    }

    const topicName = createTopicNameFromStation(station);
    subscribe(topicName, callback);
}

//
//
async function callAWSAPI(token, sub, topic, action){
    // const url = "https://dsfuidnc25.execute-api.us-east-2.amazonaws.com/Testing";
    // const url = "https://jjhe6iown5.execute-api.us-east-1.amazonaws.com/testing/alerts";
    // const url = "https://dvalerts.test.njtransit.com/alerts";
    const url = "https://dvalerts.njtransit.com/alerts";
    // const url = "https://d-ppaal0cd0l.execute-api.us-east-1.amazonaws.com/";

    // prod URL
    // const url = "https://bmas6vo7ge.execute-api.us-east-1.amazonaws.com/testing/alerts";


    const params = {
        "action": action, 
        "topicName": topic, 
        "user": token, 
        "metadata": "", 
        "method": "push",
        "subArn": sub
    };

    console.log("CALL AWS API", params, url);

    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    });

    console.log("GOT A RESPONSE?", rawResponse, rawResponse.body, rawResponse.json);

    let content = {} 
    
    if(rawResponse && rawResponse.json && typeof (rawResponse.json) === "function"){
        try{
            content = await rawResponse.json();
        }
        catch(ex){
            console.error("ERROR PARSING RESPONSE", ex);
        }
    }

    console.log("Response content", content);

    return content;
}

//
//
function createTopicNameFromStop(stop, trip, interval){
    // station, train #, time, interval
    // using interval of 5 for now

    console.log("CREATE TOPIC NAME FROM STOP", stop, trip, interval);

    const station = stop.Description.replaceAll(" ", "__");
    const trainID = trip.busid || trip.tripId;
    const time = stop.UTC.replaceAll(" ", "__").replaceAll(":", "_C_");
    // const interval = 5;

    let ret = `__${station}_-_${trainID}_-_${time}`;

    if(interval){
        ret += `_-_${interval}`
    }

    return ret;
}

// 
//
function createTopicNameFromStation(station){

    // let fullname = "";

    // terminals.forEach(t => {
    //     if(t.bus_terminal_code === station){
    //         fullname = t.bus_terminal_name;
    //         return;
    //     }
    // });

    // if(fullname){
    //     return fullname.replaceAll(" ", "__");
    // }

    return station.replaceAll(" ", "__");
}

//
//
export default {
    PLATFORM,
    PWA,
    subscribe,
    unsubscribe,
    checkIfAlertIsSet,
    runIntervalConfirmation,
    stationMessageSubscribe,
    clearOldSubs
}

function pwaSVG(){
    const span = document.createElement("span");
    span.style = "margin: 1rem 0; width: 2rem; height: 2rem;";
    span.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><script xmlns=""/><style>.st9{fill:#636365}</style><g id="Layer_2"><path class="st9" d="M47.71 60.12H16.29c-2.86 0-5.18-2.33-5.18-5.18V27.66c0-2.86 2.33-5.18 5.18-5.18h7.38v5.08h-7.38a.1.1 0 0 0-.1.1v27.28c0 .06.05.1.1.1h31.43a.1.1 0 0 0 .1-.1V27.66a.1.1 0 0 0-.1-.1h-7.38v-5.08h7.38c2.86 0 5.18 2.33 5.18 5.18v27.28c0 2.86-2.33 5.18-5.19 5.18z"/><path class="st9" d="M42.76 13.42l-8.77-8.77c-1.05-1.03-2.76-1.03-3.83.02l-8.75 8.75c-.99.99-.99 2.6 0 3.59.99.99 2.6.99 3.59 0l4.38-4.38v29.41h5.08V12.29l4.72 4.72c.5.5 1.15.74 1.8.74s1.3-.25 1.8-.74c.97-.99.97-2.6-.02-3.59z"/></g><script xmlns=""/><script xmlns=""/></svg>';
    return span;
}