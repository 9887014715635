
This component just displays the current time in AM/PM
along with a clock icon

<template>
    <div v-if="!$isSmallScreen" :class="'current-time' + (horizontal ? ' horizontal': '') + (dark ? ' dark' : '')">
        <div class="clock-icon">
            <Clock />
        </div>
        <div class="time-value">{{time}}</div>
    </div>
</template>

<script>
    import { getCurrentServerTime } from "@/requests/OptionRequests";
    import Clock from "@/components/Clock";

    let interval = null;

    export default {
        name: "ClockDisplay",
        props: ["horizontal", "dark"],
        components: {
            Clock
        },
        data(){
            return {
                time: ""
            }
        },
        created(){
            this.setCurrentTime();
            interval = setInterval(this.setCurrentTime, 5000);
        },
        destroyed() {
            if(interval){
                clearInterval(interval);
            }
        },
        methods: {
            setCurrentTime() {
                getCurrentServerTime()
                .then(({data}) => {
                    this.time = data.serverTime;
                });
                // this.time = (new Date()).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            }
        }
    }
</script>

<style scoped>
    .current-time {
        position: absolute;
        bottom: calc(2 * var(--mvh));
        left: 0;
        color: white;
        z-index: 1000;
        font-weight: bold;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .current-time.horizontal {
        position: relative;
        bottom: 0;
        flex-direction: row-reverse;
        width: fit-content;
    }

    .current-time {
        color: inherit;
    }

    .time-value {
        font-size: 1.7rem;
    }

    .clock-icon {
        font-size: 2.5rem;
        margin-bottom: calc(1 * var(--mvh));
    }

    .horizontal .clock-icon{
        font-size: 1.7rem;
        margin-bottom: 0;
        margin-left: 1rem;
    }

    /* @media only screen and (max-width: 600px) {
        .current-time {
            display: none;
        }
    } */
</style>