
let mapClass = null;

const setupMapTextOverlay = () => {

    if(!mapClass){

        class TextOverlay extends window.google.maps.OverlayView {

            constructor(pos, txt, cls, map, style) {
                super();
                this.pos = pos;
                this.txt_ = txt;
                this.cls_ = cls;
                this.map_ = map;
                this.style_ = style;
            }
        
            onAdd() {

                console.log("OVERLAY ON ADD");
        
                // Note: an overlay's receipt of onAdd() indicates that
                // the map's panes are now available for attaching
                // the overlay to the map via the DOM.
        
                // Create the DIV and set some basic attributes.
                var div = document.createElement('DIV');
                div.className = this.cls_;
        
                if(this.style_){
                    div.style.cssText = this.style_;
                }
        
                div.innerHTML = this.txt_;
        
                // Set the overlay's div_ property to this DIV
                this.div_ = div;
                var overlayProjection = this.getProjection();

                
                // var position = overlayProjection.fromLatLngToDivPixel(this.pos);
                var position = overlayProjection.fromLatLngToContainerPixel(this.pos);
                div.style.left = position.x + 'px';
                div.style.top = position.y + 'px';
                // We add an overlay to a map via one of the map's panes.

                console.log("ADD OVERLAY", this, overlayProjection, "DDDD", position);
        
                var panes = this.getPanes();
                panes.floatPane.appendChild(div);

                console.log("OVERLAY", panes, div);
            }
        
            draw() {
        
        
                var overlayProjection = this.getProjection();
        
                // Retrieve the southwest and northeast coordinates of this overlay
                // in latlngs and convert them to pixels coordinates.
                // We'll use these coordinates to resize the DIV.
                var position = overlayProjection.fromLatLngToDivPixel(this.pos);
        
        
                var div = this.div_;
                div.style.left = position.x + 'px';
                div.style.top = position.y + 'px';
                }
                //Optional: helper methods for removing and toggling the text overlay.  
            onRemove() {
                this.div_.parentNode.removeChild(this.div_);
                this.div_ = null;
            }
        
            hide() {
                if (this.div_) {
                    this.div_.style.visibility = "hidden";
                }
            }
        
            show() {
                if (this.div_) {
                this.div_.style.visibility = "visible";
                }
            }
        
            toggle() {
                if (this.div_) {
                if (this.div_.style.visibility == "hidden") {
                    this.show();
                } else {
                    this.hide();
                }
                }
            }
        
            toggleDOM() {
                if (this.getMap()) {
                this.setMap(null);
                } else {
                this.setMap(this.map_);
                }
            }
        }

        mapClass = TextOverlay;
    }

    return mapClass;
}

export default setupMapTextOverlay;


/*


// from https://stackoverflow.com/questions/3953922/is-it-possible-to-write-custom-text-on-google-maps-api-v3

const setupTextOverlay = function(googleMaps) {

    function TextOverlay(pos, txt, cls, map, style) {

        let overlay = new googleMaps.OverlayView();
        
        overlay.pos_ = pos;
        overlay.txt_ = txt;
        overlay.cls_ = cls;
        overlay.map_ = map;
        overlay.style_ = style;
        overlay.div_ = null;
    
        // We define a property to hold the image's
        // div. We'll actually create this div
        // upon receipt of the add() method so we'll
        // leave it null for now.
    
        // overlay.prototype = new googleMaps.OverlayView();
        
        console.log("OVERLAY PROTOTYPE", googleMaps, "DDDD", overlay, overlay.prototype);
        
        //
        //
        overlay.onAdd = function() {

            console.log("DO I GET CALLED ON ADD OVERLAY?");
        
            // Note: an overlay's receipt of onAdd() indicates that
            // the map's panes are now available for attaching
            // the overlay to the map via the DOM.
        
            // Create the DIV and set some basic attributes.
            var div = document.createElement('DIV');
            div.className = this.cls_;
            if(this.style_){
                div.attributes.style = this.style_;
            }
        
            div.innerHTML = this.txt_;
        
            // Set the overlay's div_ property to this DIV
            this.div_ = div;
            var overlayProjection = this.getProjection();
            var position = overlayProjection.fromLatLngToDivPixel(this.pos_);

            div.style.left = position.x + 'px';
            div.style.top = position.y + 'px';
            // We add an overlay to a map via one of the map's panes.
        
            var panes = this.getPanes();
            panes.floatPane.appendChild(div);
        }

        //
        //
        overlay.draw = function() {

            console.log("DO I GET CALLED DRAW OVERLAY");
        
            var overlayProjection = this.getProjection();
        
            // Retrieve the southwest and northeast coordinates of this overlay
            // in latlngs and convert them to pixels coordinates.
            // We'll use these coordinates to resize the DIV.
            var position = overlayProjection.fromLatLngToDivPixel(this.pos_);
        
            var div = this.div_;
            div.style.left = position.x + 'px';
            div.style.top = position.y + 'px';
        
        }

        //Optional: helper methods for removing and toggling the text overlay.  
        overlay.onRemove = function() {
            this.div_.parentNode.removeChild(this.div_);
            this.div_ = null;
        }
        overlay.hide = function() {
            if (this.div_) {
                this.div_.style.visibility = "hidden";
            }
        }
        
        overlay.show = function() {
            if (this.div_) {
                this.div_.style.visibility = "visible";
            }
        }
        
        overlay.toggle = function() {
            if (this.div_) {
                if (this.div_.style.visibility == "hidden") {
                    this.show();
                } else {
                    this.hide();
                }
            }
        }
        
        overlay.toggleDOM = function() {
            if (this.getMap()) {
                this.setMap(null);
            } else {
                this.setMap(this.map_);
            }
        }

        // Explicitly call setMap() on this overlay
        overlay.setMap(map);

        // and then return the newly created object
        return overlay;
    }

    return TextOverlay;
}

export default setupTextOverlay;


*/