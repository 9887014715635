

<template>
    <div @click="alertToggle" style="position: relative; cursor: pointer;"> 
        <!-- <svg data-v-4232c837="" class="svg-inline--fa fa-bell fa-w-20" aria-hidden="true" data-prefix="fas" data-icon="bell" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M633.82 458.1l-90.62-70.05c.19-1.38.8-2.66.8-4.06.05-7.55-2.61-15.27-8.61-21.71-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84c-40.33 8.38-74.66 31.07-97.59 62.57L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.35 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.42-6.97 4.17-17.02-2.81-22.45zM157.23 251.54c-8.61 67.96-36.41 93.33-52.62 110.75-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h241.92L157.23 251.54zM320 512c35.32 0 63.97-28.65 63.97-64H256.03c0 35.35 28.65 64 63.97 64z"></path></svg> -->
        <!-- <svg data-v-4232c837="" class="svg-inline--fa fa-bell-slash fa-w-20" aria-hidden="true" data-prefix="fas" data-icon="bell-slash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M633.82 458.1l-90.62-70.05c.19-1.38.8-2.66.8-4.06.05-7.55-2.61-15.27-8.61-21.71-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84c-40.33 8.38-74.66 31.07-97.59 62.57L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.35 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.42-6.97 4.17-17.02-2.81-22.45zM157.23 251.54c-8.61 67.96-36.41 93.33-52.62 110.75-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h241.92L157.23 251.54zM320 512c35.32 0 63.97-28.65 63.97-64H256.03c0 35.35 28.65 64 63.97 64z"></path></svg> -->
        <div :style="
            `position: absolute; top: calc(50% + 0.25rem); left: calc(0% - 1.4rem); color: black;`
            +
            ($isMobile
            ? 
            `transform: translate(-50%, 0%) scale(1.8);`
            :
            `transform: translate(-50%, 0%) scale(1.4);`)"
        >
            <i class="fas fa-bell"></i>
        </div>
        <div :class="(alreadyActive ? 'active' : 'base') + (processing ? ' processing': '') " :style="
            `position: absolute; top: calc(50% + 0.25rem); left: calc(0% - 1.4rem);`
            +
            ($isMobile
            ? 
            `transform: translate(-50%, 0%) scale(1.5);`
            :
            `transform: translate(-50%, 0%) scale(1.2);`)"
        >
            <i class="fas fa-bell"></i>
        </div>
    </div>
</template>

<script>

    import SubscriptionHandler from "@/SubscriptionHandler";

    export default {
        name: "AlertBell",
        props: ["stop", "trip"],
        components: {

        },
        data() {
            return {
                alreadyActive: false,
                topicName: null,
                sub: null,
                processing: false
            }
        },
        created(){
            // do anything here?
            // check active status of alert?

            console.log("DO WE HAVE STOP AND TRIP?", this.$props);

            const isSet = SubscriptionHandler.checkIfAlertIsSet(this.$props.stop, this.$props.trip);
            this.alreadyActive = isSet;
        },
        methods: {
            alertToggle() {
                if(this.alreadyActive){
                    this.setAlertProcessing(true);
                    SubscriptionHandler.unsubscribe(this.topicName, this.sub, () => {
                        this.alreadyActive = false;
                        this.setAlertProcessing(false);
                    });
                }
                else{
                    SubscriptionHandler.runIntervalConfirmation(this.$props.stop, this.$props.trip, this.completeAlert, this.$isMobile);
                }
            },
            completeAlert(topic){
                this.setAlertProcessing(true);
                SubscriptionHandler.subscribe(topic, (token, sub) => {
                    this.topicName = topic;
                    this.sub = sub;
                    this.alreadyActive = true;
                    this.setAlertProcessing(false);
                });
            },
            setAlertProcessing(value){
                this.processing = value;
                console.log("ALERT BELL SET ALERT PROCESSING");
                this.$emit("set-alert-processing", value);
            }
        }
    }
</script>

<style scoped>

    .base {
        color: currentColor;
        border-radius: 50%;
    }

    .active {
        color: gold;
    }

    .processing {
        animation: processing-pulse 1s linear infinite;
    }

    @keyframes processing-pulse {
        0% {
            color: currentColor;
            box-shadow: 0 0 0 gold;
        }
        50% {
            color: gold;
            box-shadow: 0 0 3rem gold;
        }
        100% {
            color: currentColor;
            box-shadow: 0 0 0 gold;
        }
    }
</style>