
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getMessaging, onMessage, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    // apiKey: "AIzaSyDYuuIVc0fX_FYivIuENUZ_Tgc7v-A2d-c",
    // authDomain: "njt-push-test.firebaseapp.com",
    // projectId: "njt-push-test",
    // storageBucket: "njt-push-test.appspot.com",
    // messagingSenderId: "109456474765",
    // appId: "1:109456474765:web:656d5f4721d3ae58cf58c4",
    // measurementId: "G-RK2NPZEEFX"
    apiKey: "AIzaSyBfDH3bKDAq8fl09dC7DTCjjIGtDFqowKI",
    authDomain: "njtransit-dd84a.firebaseapp.com",
    databaseURL: "https://njtransit-dd84a.firebaseio.com",
    projectId: "njtransit-dd84a",
    storageBucket: "njtransit-dd84a.appspot.com",
    messagingSenderId: "900611467684",
    appId: "1:900611467684:web:f4cb61daa81840cbe87931"
};

let getNewToken = () => { 
    return new Promise((resolve) => { resolve("NOT A REAL TOKEN"); }) 
};

let oml = () => { 
    console.log("NOT A REAL MESSAGE LISTENER");
    return new Promise((resolve) => { resolve({notification: {title: "FAKE", body: "ALSO FAKE"}}); }) 
};

// Initialize Firebase
if(window.Notification){ 
    const app = initializeApp(firebaseConfig);
    
    const messaging = getMessaging(app);
    
    // for when the app is already open?
    oml = () =>
        new Promise((resolve) => {
            onMessage(messaging, (payload) => {
                console.log("ON MESSAGE?", Date.now, payload, resolve);
                // alert(payload.notification.title + "   " + payload.notification.body);
                alert(payload.notification.body);
                resolve(payload);
        });
    });

    getNewToken = (key) => {
        return getToken(messaging, key);
    }
}

export const onMessageListener = oml;
export default getNewToken;
