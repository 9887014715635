<template>
    <div class="qr-code-container">
        <VueQRCodeComponent v-if="size > 0" :text="url" error-level="H" :size="size" />
    </div>
</template>

<script>
    import VueQRCodeComponent from 'vue-qrcode-component'

    export default {
        name: "TripsQRCode",
        props: ["url"],
        data() {
            return {
                size: 0,
            }
        },
        components: {
            VueQRCodeComponent
        },
        watch: {
            size(oldVal, newVal){
                console.log("QR SIZE", oldVal, newVal);
            }
        },
        mounted (){
            const height = window.innerHeight;
            console.log("HEIGHT", height);
            this.size = height/12;
        }
    }
</script> 

<style scoped>
    .qr-code-container {
        padding: 0.5vw;
        background-color: white;
        border-radius: 1vw;
    }
</style>