<template>
    <div :class="'rail-cars' + (cars.length ? ' has-cars' : '')" @click.stop="openExpandedCard">
        <div
            v-for="(car, index) in cars"
            :key="index"
            class="car"
            :style="`background-color: ${car.color};`"
        >
            <div class="number">
                #{{car.num}}: status
            </div>
        </div>
        <!-- <span class="expansion-caret">
            <i v-if="cars.length" class="fas fa-caret-down"></i>
        </span> -->
    </div>
</template>

<script>

    export default {
        name: "RailPassengerDensity",
        props: ["cars"],
        methods: {
            openExpandedCard(){
                // alert("Open Expanded Card");
                this.$emit("open-expanded-card");
            }
        }
    }
</script>

<style scoped>
    .rail-cars {
        display: flex;
        gap: 4px;
        position: relative;
        width: fit-content;
        height: fit-content;
    }

    .rail-cars.has-cars {
        background-color: white;
        border-radius: 1.1rem;
        padding: 0.4rem 0.75rem 0.35rem;
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
        margin-right: -0.25rem;
        border: 1px solid black;
        justify-self: center;
    }

    .mobile .rail-cars.has-cars {
        padding: 0.4rem 0.75rem;
    }

    /* .expansion-caret {
        position: absolute;
        right: 0;
        top: 0.2rem;
        transform: scale(1.3) translateX(calc(100% + 2px));
    } */

    .scrolling-layout .expansion-caret {
        display: none;
    }

    .car {
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid white;
    }

    .rail-cars:hover .car {
        box-shadow: 0 0 0px rgba(255,255,255, 0.8),
                    0 0 6px rgba(255, 255, 255, 0.8),
                    0 0 9px rgba(255,255,255, 0.8);
    }

    .mobile .car {
        width: 1rem;
        height: 1rem;
    }

    .car:last-of-type {
        border-top-right-radius: 8px;
    }

    .number {
        display: none;
    }

/*
    .car:hover {
        box-shadow: 0 0 6px 2px rgba(255,255,255,0.8),
                inset 0 0 6px rgba(255,255,255,0.8);
    }

    .car:hover .number {
        display: initial;
        position: absolute;
        top: -130%;
        left: -50%;

        background: white;
        color: black;
        padding: 2px;
        border: 1px solid black;
        border-radius: 5px;

        width:max-content;
    }
*/
</style>