var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative","cursor":"pointer"},on:{"click":_vm.alertToggle}},[_c('div',{style:(`position: absolute; top: calc(50% + 0.25rem); left: calc(0% - 1.4rem); color: black;`
        +
        (_vm.$isMobile
        ? 
        `transform: translate(-50%, 0%) scale(1.8);`
        :
        `transform: translate(-50%, 0%) scale(1.4);`))},[_c('i',{staticClass:"fas fa-bell"})]),_c('div',{class:(_vm.alreadyActive ? 'active' : 'base') + (_vm.processing ? ' processing': ''),style:(`position: absolute; top: calc(50% + 0.25rem); left: calc(0% - 1.4rem);`
        +
        (_vm.$isMobile
        ? 
        `transform: translate(-50%, 0%) scale(1.5);`
        :
        `transform: translate(-50%, 0%) scale(1.2);`))},[_c('i',{staticClass:"fas fa-bell"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }