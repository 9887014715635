
Container for the Stops list to be displayed

Handles the API call to get the stops data based on the trips object passed in as a prop,
and then iterates over the results to display the individual StopDisplay components

// DO WE NEED THIS ONE?
            :style="`height: calc(${mainHeight} * var(--mvh)); position: relative;`"

/*
    - the parent div needs to have overflow:hidden
    - and then center-grid needs to have fixed & its top set
    - but *only* for the landscape view
*/

<template>
    <div style="position: fixed; width: 100vw;">    
        <div 
            class="center-grid scroll-check"
        >
            <div class="loader-container">
                <Loader v-if="loading || alertProcessing" :isProcessing="alertProcessing" />
            </div>

            <div class="no-stops-message" v-if="initialLoadComplete && stops.length === 0">
                No Stop Information Available At This Time
            </div>
            <div v-else-if="initialLoadComplete" class="estimatedMessage">              
                <span class="clock-icon"><Clock /></span> Estimated Arrival Times
            </div>

            <StopDisplay 
                v-bind:key="stop.id" 
                v-for="(stop, index) in stops" 
                v-bind:dataIndex="index"
                v-bind:stop="stop" 
                v-bind:query="$props.query"
                v-bind:route="route"
                :trip="trip"
                @set-alert-processing="setAlertProcessing"
            />
        </div>
    </div>
</template>

<script>
    import StopDisplay from "@/data_display/StopDisplay";

    import Loader from "@/components/Loader";
    import Clock from "@/components/Clock";

    // import { getStops as getStops } from "@/requests/DataRequests";
    import { getStopsNEW as getStops } from "@/requests/DataRequests";

    import { insertPlane } from "@/Utilities";

    import SubscriptionHandler from "@/SubscriptionHandler";

    export default {
        name: "StopsContainer",
        props: ["query", "overflow", "mainDisplayHeightReduction", "tripObj"],
        components: {
            StopDisplay,
            Loader,
            Clock
        },
        data() {
            return {
                initialLoadComplete: false,
                stops: [],
                mainHeight: 91,
                route: "",
                trip: null,
                loading: true,
                alertAlreadyActive: false,
                destination: null,
                stationSub: null,
                stationAlertTopic: null,
                alertProcessing: false
            }
        },
        watch:{
            destination(){
                console.log("CHECK IF ALERT ALREADY SET", this.destination);
                const sub = SubscriptionHandler.checkIfAlertIsSet(null, null, this.destination)
                this.stationSub = sub.arn;
                this.stationAlertTopic = sub.topic;
                this.alertAlreadyActive = !!sub;
            },
            alertAlreadyActive(){
                console.log("ALERT ALREADY ACTIVE SET");
            },
            alertProcessing(){
                console.log("ALERT PROCESSING");
            }
        },
        created() {
            if(this.$props.mainDisplayHeightReduction){
                this.mainHeight -= this.$props.mainDisplayHeightReduction;
            }

            if(this.$props.query.footer !== false && this.$isSmallScreen){
                if(this.$isSmallScreen){
                    this.mainHeight = "83";
                }
                else{
                    this.mainHeight = "80";
                }
            }

            SubscriptionHandler.clearOldSubs();

            if(this.$isRotatedMobile){
                this.mainHeight -= 3;
            }

            const obj = this.$props.tripObj;
            console.log("STOPS CONTAINER OBJECT", obj);

            if(obj){
                // deal with the RAIL data
                const pageType = this.$route.path.split("/")[1];
                if(pageType === "rail"){
                    obj.mode = "RAIL";
                }

                getStops(obj)
                .then(({data}) => {
                    console.log("GET STOPS", data, obj);
                    this.loading = false;
                    this.initialLoadComplete = true;

                    if(!data.stops){
                        // no stops
                        return;
                    }

                    const copy = [...data.stops];

                    const defaultDestination = data.destination;

                    console.log("DEFAULT DESTINATION");

                    if(defaultDestination){
                        this.$emit("setDestination", defaultDestination);
                        this.destination = defaultDestination;
                    }

                    let lastDepartedIndex = -1;

                    copy.forEach((stop, index) => {
                        // be sure to pick out the start and end stops
                        const status = stop.Status;

                        if(status === "DEPARTED" || status === "departed" || status === "Departed"){
                            console.log("STOP DATA DEPARTED", index);
                            lastDepartedIndex = index;
                        }

                        if(index === 0){
                            stop.isStart = true;
                        }
                        if(index === data.stops.length -1){
                            const description = stop.Description;
                            let dest = description ? insertPlane(description) : stop.TimePoint;

                            console.log("NON DEFAULT DESTINATION", stop, description, dest);

                            console.log("LAST SET DESTINATION EMIT", dest);

                            if(dest === "JOURNAL SQUARE TRANSPORTATION CENTER"){
                                dest = "JOURNAL SQUARE";
                                this.$emit("setDestination", dest);
                                this.destination = "JOURNAL SQUARE TRANSPORTATION CENTER";
                            }
                            else if(!defaultDestination){
                                this.$emit("setDestination", dest);
                                this.destination = dest;
                            }

                            stop.isEnd = true;
                        }
                    });

                    if(lastDepartedIndex > 0){
                        for(let i = 0; i < lastDepartedIndex; i++){
                            if(copy[i].Status !== "cancelled"){
                                copy[i].Status = "departed";
                            }
                        }
                    }

                    console.log("FINAL STOP DATA", data.stops);

                    this.trip = data.trip || obj;
                    this.stops = copy;
                    this.$emit("setTripObj", this.trip);
                });

                if(obj.route){
                    this.route = obj.route;
                }
            }
        },
        methods: {

        }
    }
</script>

<style scoped>

    .center-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        height: min(calc(91 * var(--mvh)), 91vh);
        padding-top: calc(0.9 * var(--mvh));
    }

    .landscape-container .center-grid {
        position: fixed;
        top: 9vh;
        width: 100vw;
    }

    .full-rotate .mobile .center-grid {
        height: min(calc(80 * var(--mvh)), 80vh);
    }

    .mobile .center-grid {
        height: min(calc(83 * var(--mvh)), 83vh);
    }

    .no-stops-message {
        margin-top: 20vh;
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        margin-left: 5%;
        padding: calc(2 * var(--mvh)) 0;
        font-weight: 600;
        font-size: 2.5rem;
        letter-spacing: 0.04rem;
    }

    .loader-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .estimatedMessage {
        position: relative;
        width: 98vw;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.4rem 0.8vw 0.4rem;
        font-weight: 500;
        font-size: 1.25rem;
    }

    .mobile .estimatedMessage {
        font-size: 1rem;
    }

    .clock-icon {
        transform: scale(1.5) translateY(0.05rem);
        margin-right: 0.75rem;
    }

</style>