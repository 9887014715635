/* eslint-disable no-mixed-spaces-and-tabs */

/*
	API calls for getting TRIPS and for getting STOPS
*/

import axios from "axios";
import { BaseURL, GenerateAudioURL, createFormData, getCurrentStore } from "./BaseRequestValues";

import RailRequests from "./RailRequests";

import axiosRetry from "axios-retry";

const FAILURE_LIMIT = 60;

let consecutiveFailureCount = 0;
let initialFailureTime = null;

//
//
let reqToken = "0123456789";

function viewReqToken(){
    return reqToken;
}

function setReqToken(v){
    reqToken = v;
}

// retry the requests is they initially fail
axiosRetry(axios, {
	retries: 1, // number of retries
	retryCondition: (error) => {
        console.log("AN ERROR", error);
        console.log("GET CURRENT VUE?", getCurrentStore(), error);

        if(error.response){
            console.log("AN ERROR DATA", error.response.data);
        }

        consecutiveFailureCount++;
        if(!initialFailureTime){
            initialFailureTime = Date.now();
        }

        console.log("CONSECUTIVE FAILURES RAIL", getCurrentStore(), consecutiveFailureCount);

        // if(consecutiveFailureCount >= FAILURE_LIMIT){
        if(failureDiff()){
            // call to the store to set disconnected mode
            // how do I call to the store from here?
            // do I need to pass something in to use as a callback?
            console.log("EXCEEDED CONSECUTIVE FAILURE LIMIT");
            getCurrentStore().commit("setIsDisconnected", true);
        }
		// if retry condition is not specified, by default idempotent requests are retried
		// return error.response.status === 503;

        /*
{
  "errorMessage": "Invalid token."
}
        */

        // make the retry probabilistic so it's sure to stop eventually no matter what
        const r = Math.random();
		return (r > 0.2);
	}
});

function failureDiff() {
    const now = Date.now();

    console.log("FAILURE DIFF", now, initialFailureTime, now - initialFailureTime);

    if(!initialFailureTime){
        return false;
    }

    const sec = Math.abs(now - initialFailureTime)/1000;
    return sec >= FAILURE_LIMIT;
}

//
// check if the current token is valid
//
function checkToken(){
	const promise = new Promise((resolve) => {
		setReqToken("0123456789");
		resolve();
	});

	return promise;
}

/*
//
//
function isValidToken(){
    // const url = `${BaseURL}/TrainData/isValidToken/${token}`;
    // return axios.get(url);

    const url = `${BaseURL}/TrainData/isValidToken`;

    const data = createFormData({token});

    const config = {
        method: "post",
        url,
        data,
        timeout: 2000
    }

    return axios(config);
}
*/

//
// get new token if one doesn't yet exist
//
function getToken() {
	const promise = new Promise((resolve) => {
		setReqToken("0123456789");
		resolve();
	});

	return promise;
}

//
//
function resetErrors() {
    consecutiveFailureCount = 0;
    initialFailureTime = null;
    getCurrentStore().commit("setIsDisconnected", false);
}

//
//
function getRetryTimeout(rt){
    if(rt === 0){
        return 2000;
    }
    else if(rt === 1 || rt === -1){
        return 4000;
    }
    else if(rt === 2) {
        return 10000;
    }
    else if(rt > 2 && rt < 10) {
        return 20000;
    }
}

//
//
function runRequest(req, reAttemptCount = 0){
    return checkToken()
    .then(() => {
        const rt = viewReqToken();
        if(!rt || rt.length < 10){
            // something very weird has happened here...
            setReqToken(null);
            if(reAttemptCount >= 0 && reAttemptCount <= 4) {
                window.setTimeout(() => {
                    getToken()
                    .then(() => {
                        runRequest(req, reAttemptCount + 1);
                    });
                }, getRetryTimeout(reAttemptCount));
            }

            return;
        }

        console.log("FINISHED CHECKING TOKEN");
        // reset the failure count
        return req(rt);
    })
    .catch(err => {
        console.log("CAUGHT EXCEPTION", err);

        let result = "";
        if(err.response){
            result = err.response.data;
            console.log("CAUGHT EXCEPTION RESULT", result);
        }

        if(result && result.errorMessage === "Invalid token."){
            // clear it so there's no confusion
            console.log("WE HAVE AN INVALID TOKEN", reAttemptCount);

            setReqToken(null);
            if(reAttemptCount >= 0 && reAttemptCount <= 10) {
                window.setTimeout(() => {
                    console.log("INVALID TOKEN RETRY!!!");
                    getToken()
                    .then(() => {
                        runRequest(req, reAttemptCount + 1);
                    });
                }, getRetryTimeout(reAttemptCount));
            }
            else if(reAttemptCount === -1){
                window.setTimeout(() => {
                    console.log("INVALID TOKEN RETRY!!!");
                    getToken()
                    .then(() => {
                        runRequest(req, -2);
                    });
                }, getRetryTimeout(reAttemptCount));
            }
        }
        else if(reAttemptCount >= 0 && reAttemptCount <= 10) {
            window.setTimeout(() => {
                console.log("OTHER ERROR RETRY!!!");
                runRequest(req, reAttemptCount + 1);
            }, getRetryTimeout(reAttemptCount));
        }
        else if(reAttemptCount === -1){
            window.setTimeout(() => {
                console.log("OTHER ERROR RETRY!!!");
                runRequest(req, -2);
            }, getRetryTimeout(reAttemptCount));
        }
    });
}

//
// scrolling onscreen audio message captions
// just a basic structure setup for now
//
export function getAudioMessage(ip, mode) {

	if(mode.toUpperCase() === "RAIL"){
		return RailRequests.getAudioMessage(ip);
	}

	const req = () => {
		console.log("REQUEST FOR AUDIO MESSAGE", ip, mode);

		const requestURL = GenerateAudioURL(ip);
		console.log("Audio reuest URL", requestURL);
		return axios.get(requestURL)
		.then(({data}) => {
			resetErrors();
			return {data};
		});
	};
	return runRequest(req);
}

//
//
export function getTripsNEW(params){

	if(params.mode === "RAIL") {
		console.log("GET TRIPS PARAMS RAIL");
		return RailRequests.getRailTrips(params);
	}

	const req = () => {

		console.log("GET TRIPS PARAMS", params);
	
		//
		// and now for all of the non-rail trips data
		//
	
		let data = {};
	
		const base_data = {
			stop: params.stop,
			route: params.route,
			direction: params.direction,
			ip: params.sign
		};
	
		if(params.paramid){
			base_data.paramid = params.paramid;
		}
	
		if(params.mode !== "BUS"){
			// this is for lightrail
			// HBLR is route 342...  RL is 343 and NLR is 7
	
			let route = "";
	
			if(params.route === "HBLR"){
				// route = "342";
				route = "HBLR";
			}
			else if(params.route === "RL"){
				// route = "343";
				route = "RvLN"
			}
			else {
				// route = "7";
				route = "NLR";
			}
	
			base_data.route = route;
			base_data.direction = "";
		}
	
		console.log("GET TRIPS ROUTE PARAMS BASE?", params, base_data);
	
		data = createFormData(base_data);
	
		const url = `${BaseURL}/api/busDV/getBusDV`;
	
		var config = {
			method: 'post',
			url,
			data
		};
	
		return axios(config)
		.then(({data}) => {
	
			resetErrors();
	
	
			if(data && data.DVTrip && data.DVTrip.length){
				for(let i = 0; i < data.DVTrip.length; i++){
					const trip = data.DVTrip[i];
					trip.sortElement = numberSplit(trip.public_route);
				}

				// does the internal js sort maintain relative order?
				data.DVTrip.sort((a,b) => {
					console.log("SORT COMPARE", a.sortElement, b.sortElement, a.public_route.localeCompare(b.public_route));
					
					const numA = a.sortElement.num;
					const numB = b.sortElement.num;

					if(numA === numB){
						return a.sortElement.non.localeCompare(b.sortElement.non);
					}
					else if(numA && numB){
						if(numA < numB) {
							return -1;
						}
						return 1;
					}
					else if(numA){
						return -1;
					}
					else if(numB){
						return 1;
					}
					else {
						return a.sortElement.non.localeCompare(b.sortElement.non);
					}
					// return a.public_route.localeCompare(b.public_route);
				});
			}
	
			console.log("INTERNAL DATA SORTED?", data);
	
			return {
				data
			}
		})
		.catch(err => console.error(err));
	};

	return runRequest(req);
}

function numberSplit(value){
	// get the initial number (if any) at the start of the value
	// return an object with two fields: num & non
	const ret = {num: "", non: ""};

	const n = parseInt(value);

	if(n){
		ret.num = n;
		ret.non = value.replace(n, "");
	}
	else{
		ret.non = value;
	}

	return ret;
}

//
//
export function getStopsNEW(trip){

	if(trip.mode === "RAIL"){
		return RailRequests.getRailStops(trip);
	}

	const req = () => {

		console.log("GET STOPS ??", trip);
	
		const data = createFormData(trip);
	
		const url = `${BaseURL}/api/busDV/getTripStops`;
	
		var config = {
			method: 'post',
			url,
			data
		};
	
		return axios(config)
		.then(({data}) => {
			console.log("NEW STOPS REQUEST DATA", data);
			
			resetErrors();
	
			return new Promise((resolve) => {
				const ret = 
				{
					data: {
						trip: null,
						stops: data
					}
				};
	
				resolve(ret);
			})
		});
	}

	return runRequest(req);
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/*
	ALL OF THE BELOW IS USED FOR TESTING PURPOSES
*/

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

function convertTrip(trip){
	const obj = {
		public_route: trip.route,
		header: trip.dest,
		lanegate: trip.gate,
		departuretime: "",
		remarks: "",
		internal_trip_number: trip.id,
		sched_dep_time: trip.time,
		timing_point_id: trip.id,
		message: trip.message,
		fullscreen: trip.fullscreen,
		density: trip.density
	};

	return obj;
}

//
//
//
export function getTrips(){

	let tripsStart = [
		{id: 1, busid:"3212", density: "light", "route":"107","dest":"Irvington Ivy Hill","gate":"307","time":"9:25 PM"},
		{id: 2, busid:"1112", density: "medium", "route":"107X","dest":"Express Irvington South Orange","gate":"307","time":"9:55 PM"},
		{id: 3, busid:"2238", density: "heavy", "route":"108","dest":"Newark","gate":"308","time":"10:00 PM"},
		{id: 4, busid:"3212", density: "light", "route":"112","dest":"Scotch Plains Union Tech","gate":"222-4","time":"9:35 PM"},
		{id: 5, busid:"3212", density: "EMPTY", "route":"112","dest":"Clark Terminal Ave","gate":"316","time":"10:35 PM"},
		{id: 6, busid:"3212", density: "light", "route":"113S","dest":"Dunellen Salem Road","gate":"316","time":"10:05 PM"},
		{id: 7, busid:"3212", density: "", "route":"113N","dest":"Dunellen North Ave","gate":"316","time":"11:05 PM"},
		{id: 8, busid:"3212", density: "light", "route":"114","dest":"Dunellen","gate":"222-1","time":"9:30 PM"},
		{id: 9, busid:"3212", density: "EMPTY", "route":"114","dest":"Somerville","gate":"222-1","time":"10:00 PM"},
		{id: 10, busid:"3212", density: "", "route":"115","dest":"Avenel","gate":"316","time":"10:30 PM"},
	];

	shuffleArray(tripsStart);

	const limit = /*Math.ceil(Math.random()*5)*/ + 2;

	tripsStart = tripsStart.slice(0, limit);

	// tripsStart.push({message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", fullscreen: Math.random() > 0.1 ? "FALSE" : "TRUE"});
	const messages = ["Lorem ipsum dolor sit amet consectetur", "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur fuga iure architecto nam perferendis molestias? Expedita error nostrum iste accusantium iure, commodi est? Tempora suscipit velit saepe odit ipsa facere nemo non eaque nesciunt culpa amet, voluptas molestias, ipsum iure quod facilis mollitia veniam incidunt totam ad aspernatur doloremque veritatis!"];
	tripsStart.push({message: messages[Math.floor(Math.random()*2)], fullscreen: Math.random() > 0.1 ? "FALSE" : "TRUE"});

	const tripsConvert = tripsStart.map(t => convertTrip(t));

	const promise = new Promise((resolve) => {
		resolve({data: tripsConvert});
	})

	return promise;
}

//
//
//
export function getTripsOLD(params) {

	console.log("GET TRIPS", params);

    const data = {
        trips: [],
        message: "NJ TRANSIT bus tickets and passes are currently being cross-honored on all NJ TRANSIT rail lines to increase travel options for bus customers. NJ TRANSIT has resumed front-door bus boarding and cash fare collection on buses with protective barriers installed around the operator. Buses without protective barriers will continue to board from the rear, and cash collection will remain suspended. ",
        isEmergency: Math.random() > 0.95
    };

    if(data.isEmergency){
        data.message = "THIS IS AN EMERGENCY MESSAGE!!!!";
	}
	
	const choice = Math.ceil(Math.random() * 3);

    if(choice == 1){
        data.trips = [
            {id: 1, busid:"3212", density: "light", "route":"107","dest":"Irvington Ivy Hill","gate":"307","time":"9:25 PM"},
            {id: 2, busid:"1112", density: "medium", "route":"107X","dest":"Express Irvington South Orange","gate":"307","time":"9:55 PM"},
            {id: 3, busid:"2238", density: "heavy", "route":"108","dest":"Newark","gate":"308","time":"10:00 PM"},
            {id: 4, busid:"3212", density: "light", "route":"112","dest":"Scotch Plains Union Tech","gate":"222-4","time":"9:35 PM"},
            {id: 5, busid:"3212", density: "light", "route":"112","dest":"Clark Terminal Ave","gate":"316","time":"10:35 PM"},
            {id: 6, busid:"3212", density: "light", "route":"113S","dest":"Dunellen Salem Road","gate":"316","time":"10:05 PM"},
            {id: 7, busid:"3212", density: "light", "route":"113N","dest":"Dunellen North Ave","gate":"316","time":"11:05 PM"},
            {id: 8, busid:"3212", density: "light", "route":"114","dest":"Dunellen","gate":"222-1","time":"9:30 PM"},
            {id: 9, busid:"3212", density: "light", "route":"114","dest":"Somerville","gate":"222-1","time":"10:00 PM"},
            {id: 10, busid:"3212", density: "light", "route":"115","dest":"Avenel","gate":"316","time":"10:30 PM"},
            {id: 11, busid:"3212", density: "light", "route":"116","dest":"Perth Amboy","gate":"302","time":"10:20 PM"},
            {id: 12, busid:"3212", density: "light", "route":"119","dest":"Jersey City And Bayonne Via Jfk Blvd","gate":"11","time":"9:45 PM"},
            {id: 13, busid:"3212", density: "light", "route":"123","dest":"Jersey City Christ Hosp","gate":"214-3","time":"9:19 PM"},
            {id: 14, busid:"3212", density: "light", "route":"124","dest":"Secaucus County Ave & Ups Dr","gate":"317","time":"9:30 PM"},
            {id: 15, busid:"3212", density: "light", "route":"125","dest":"Jersey City Journal Square","gate":"214-1","time":"9:40 PM"},
            {id: 16, busid:"3212", density: "light", "route":"126","dest":"Hoboken-Path","gate":"204-1","time":"9:22 PM"},
            {id: 17, busid:"3212", density: "light", "route":"126","dest":"Hamilton Pk Via Willow Av","gate":"205-1","time":"9:42 PM"},
            {id: 18, busid:"3212", density: "light", "route":"126","dest":"Hamilton Pk Via Hoboken","gate":"323","time":"10:15 PM"},
            {id: 19, busid:"3212", density: "light", "route":"127","dest":"Ridgefield Fulton Place","gate":"312","time":"10:45 PM"},
            {id: 20, busid:"3212", density: "light", "route":"128","dest":"North Bergen Via Blvd East","gate":"212-1","time":"9:20 PM"},
            {id: 21, busid:"3212", density: "light", "route":"129","dest":"Secaucus Junction","gate":"314","time":"9:45 PM"},
            {id: 22, busid:"3212", density: "light", "route":"129","dest":"Secaucus Junction Via Secaucus Plaza","gate":"314","time":"10:15 PM"}
        ];
    }
    else if(choice == 2){
        data.trips = [
            {id: 23, busid:"3212", density: "light", "route":"107","dest":"Irvington Ivy Hill","gate":"307","time":"9:25 PM"},
            {id: 24, busid:"8474", density: "heavy", "route":"107X","dest":"Express Irvington South Orange","gate":"307","time":"9:55 PM"},
            {id: 25, busid:"9282", density: "medium", "route":"108","dest":"Newark","gate":"308","time":"10:00 PM"}
        ];
        data.message = "Lorem Ipsum";
    }
    else if(choice == 3){
        data.trips = [
            {id: 26, busid:"2465", density: "medium", "route":"107","dest":"Irvington Ivy Hill","gate":"307","time":"9:25 PM"},
            {id: 27, busid:"3212", density: "light", "route":"107X","dest":"Express Irvington South Orange","gate":"307","time":"9:55 PM"},
            {id: 28, busid:"3212", density: "heavy", "route":"108","dest":"Newark","gate":"308","time":"10:00 PM"},
        ];
    }

    shuffleArray(data.trips);

    const promise = new Promise((resolve) => {
        resolve({data});
    });

    return promise;
}

//
//
//
export function getStops(route) {
    console.log(BaseURL, route);

    let data = [];

    const useFirst = Math.random() > 0.5;

    if(useFirst){
        data = [
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			}
            // {
			// 	id: 1,
            //     name: "New York Penn Station",
			// 	time: "",
			// 	isStart: "",
			// 	isEnd: "",
			// 	isDeparted: true,
			// 	changeList: ["Gladstone", "Jersey Coast"],
			// 	isAlertOn: ""
            // },
            // {
			// 	id: 2,
            //     name: "Secaucus Upper Lvl",
			// 	time: "",
			// 	isStart: false,
			// 	isEnd: false,
			// 	isDeparted: false,
			// 	changeList: ["Bergen County", "Gladstone Branch", "Main", "North Jersey Coast", "Pascack Valley"],
			// 	isAlertOn: false
            // },
            // {
			// 	id: 3,
            //     name: "Newark Penn Station",
			// 	time: "04:10 PM",
			// 	isStart: false,
			// 	isEnd: false,
			// 	isDeparted: false,
			// 	changeList: ["North Jersey Coast", "Raritan Valley"],
			// 	isAlertOn: false
            // },
            // {
			// 	id: 4,
            //     name: "Newark Airport",
			// 	time: "04:17 PM",
			// 	isStart: false,
			// 	isEnd: false,
			// 	isDeparted: false,
			// 	changeList: null,
			// 	isAlertOn: false
            // },
            // {
			// 	id: 5,
            //     name: "Metropark",
			// 	time: "04:30 PM",
			// 	isStart: false,
			// 	isEnd: false,
			// 	isDeparted: false,
			// 	changeList: null,
			// 	isAlertOn: false
            // },
            // {
			// 	id: 6,
            //     name: "Metuchen",
			// 	time: "04:35 PM",
			// 	isStart: false,
			// 	isEnd: false,
			// 	isDeparted: false,
			// 	changeList: null,
			// 	isAlertOn: false
            // },
            // {
			// 	id: 7,
            //     name: "Edison",
			// 	time: "04:40 PM",
			// 	isStart: false,
			// 	isEnd: false,
			// 	isDeparted: false,
			// 	changeList: null,
			// 	isAlertOn: false
            // },
            // {
			// 	id: 8,
            //     name: "New Brunswick",
			// 	time: "04:44 PM",
			// 	isStart: false,
			// 	isEnd: false,
			// 	isDeparted: false,
			// 	changeList: null,
			// 	isAlertOn: false
            // },
            // {
			// 	id: 9,
            //     name: "Princeton Junction",
			// 	time: "05:02 PM",
			// 	isStart: false,
			// 	isEnd: false,
			// 	isDeparted: false,
			// 	changeList: null,
			// 	isAlertOn: false
            // },
            // {
			// 	id: 10,
            //     name: "Hamilton",
			// 	time: "05:09 PM",
			// 	isStart: false,
			// 	isEnd: false,
			// 	isDeparted: false,
			// 	changeList: null,
			// 	isAlertOn: false
            // },
            // {
			// 	id: 11,
            //     name: "Trenton",
			// 	time: "05:22 PM",
			// 	isStart: false,
			// 	isEnd: true,
			// 	isDeparted: false,
			// 	changeList: "",
			// 	isAlertOn: false
            // }
        ];
    }
    else {
        data = [
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			},
			{
				Description: "HACKENSACK BUS TERMINAL",
				ManLaneGate: "",
				SchedDepTime: "7/21/2021 10:52:00 PM",
				SchedLaneGate: "..",
				TimePoint: "HACKTRAN",
				TripNumber: "16893430"
			}
        ];
    }

    const promise = new Promise((resolve) => {
        resolve({data});
    });

    return promise;
}

//
//
//
export const toggleAlert = (stop, setToOn) => {

	console.log("TOGGLE ALERT", stop, setToOn);

	// no data to return here, really
	const data = {
		result: "success"
	};

    const promise = new Promise((resolve) => {
        resolve({data});
    });

    return promise;
}


/*

FOR TARGETED TESTING

*/

const record1 = {
	departuretime: "Approaching",
	fullscreen: "",
	header: "HALEDON     ",
	internal_trip_number: "17147328",
	lanegate: "..",
	message: "",
	passload: "EMPTY",
	public_route: "703",
	remarks: "",
	sched_dep_time: "7/26/2021 3:09:00 PM",
	timing_point_id: "AME"
};

const record2 = {
	departuretime: "3:20 PM",
	fullscreen: "",
	header: "BERGEN COMM COLL VIA GOTHAM PARKWAY  ",
	internal_trip_number: "16893478",
	lanegate: "..",
	message: "",
	passload: "EMPTY",
	public_route: "772G",
	remarks: "",
	sched_dep_time: "7/26/2021 3:20:00 PM",
	timing_point_id: "AME"
};

const record3 = {
	departuretime: "3:20 PM",
	fullscreen: "",
	header: "HOBOKEN TERMINAL    ",
	internal_trip_number: "17131367",
	lanegate: "..",
	message: "",
	passload: "EMPTY",
	public_route: "85",
	remarks: "",
	sched_dep_time: "7/26/2021 3:20:00 PM",
	timing_point_id: "AME"
};

const mock_records = [record1, record2, record3];

let scroll_test_index = 0;
export const getTripsScrollTest = (forSize = false) => {
	return () => {

		let size = 20;
		const index = scroll_test_index % 3;

		if(forSize && index !== 2){
			size = 2;
		}
	
		const trips = [];
	
		for(let i = 0; i < size; i++){
			trips.push({...mock_records[index]});
		}
	
		// no data to return here, really
		const data = {
			message: "ABCDEFG",
			DVTrip: trips
		};
	
		const promise = new Promise((resolve) => {
			resolve({data});
		});
	
		scroll_test_index++;
	
		return promise;
	}
}
