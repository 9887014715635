<template>
    <div class="qr-container" :style="`height: calc(${$QR_HEIGHT} * var(--mvh))`">
        <div class="top-half"></div>
        <div class="bottom-half"></div>

        <div class="qr-message">
            Scan to get info on the go
            <div class="qr-phone">
                <div class="screen"></div>
                <div class="button"></div>
            </div>
            <div class="qr-accent">
                <div class="first-line"></div>
                <div class="second-line"></div>
                <div class="third-line"></div>
            </div>
        </div>
        <div class="code">
            <TripsQRCode :url="url" />
        </div>
    </div>
</template>

<script>
    import TripsQRCode from "@/components/TripsQRCode";

    export default {
        name: "QRContainer",
        props: ["url"],
        components: {
            TripsQRCode
        }
    }
</script>


<style scoped>
    .qr-container {
        position: fixed; 
        z-index: 100; 
        bottom: 0; 
        left: 0; 
        right: 0; 

        background-color: #e4edf9;

        display: flex;
        flex-direction: column;
        align-items: center;

        border: 0.2vw solid black;
        border-top: 0.3vw solid black;
        /* border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        overflow:hidden; */
    }

    .top-half {
        width: 100%;
        height: 50%;
        background: linear-gradient(45deg, blue 0%, blue 6%, purple 6%, purple 12%, orange 12%, orange 18%, #e4edf9 18%, #e4edf9 100%);
    }

    .bottom-half {
        width: 100%;
        height: 50%;
        background: linear-gradient(45deg, #e4edf9 0%, #e4edf9 80%, blue 80%, blue 88%, purple 88%, purple 94%, orange 94%, orange 100%);
    }

    .qr-message {
        position: absolute;
        top: 50%;
        left: 47%;
        transform: translate(-50%, -50%) scale(1.2);

        font-family: "Viga-Regular", sans-serif;

        text-transform: uppercase;
        font-size: 2.5rem;
        /* letter-spacing: 0.1rem; */
        /* color: white; */
    }

    .code {
        position: absolute;
        /* top: -70%;
        transform: translateY(-50%); */
        bottom: 1vw;
        right: 1vw;
    }

    .qr-accent {
        position: absolute;
        height: 100%;
        width: 10vw;
        top: 55%;
        transform: translateY(-50%);
        right: -3vw;
        /* color: white; */
    }

    .first-line {
        position: absolute;
        top: 1.3vh;
        right: -1vw;
        height: 0.3vw;
        width: 3vw;
        background: black;
        border-radius: 0.5vw;
    }

    .second-line {
        position: absolute;
        top: 2.7vh;
        right: -1.5vw;
        height: 0.3vw;
        width: 3vw;
        background: black;
        border-radius: 0.5vw;
    }

    .third-line {
        position: absolute;
        top: 4.2vh;
        right: -0.8vw;
        height: 0.3vw;
        width: 3vw;
        background: black;
        border-radius: 0.5vw;
    }

    .qr-phone {
        position: absolute;
        width: 4vh;
        height: 7vh;
        left: -10vh;
        top: 0%;
        transform: rotate(-20deg);

        border-radius: 1vh;

        background: black;
    }
    .screen {
        position: absolute;
        top: 0.6vh;
        left: 0.6vh;
        height: 5vh;
        width: 2.8vh;
        background: white;
        border-radius: 0.5vh;
    }

    .button {
        position: absolute;
        bottom: 0.5vh;
        left: 1.5vh;
        height: 0.5vh;
        width: 1vh;
        background-color: white;
    }

</style>