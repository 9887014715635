<template>
    <div>
        <LaptopNavBar v-if="!$isSignMode && !$isSmallScreen" :condensed="true" pageType="rail" :query="{}" text="Station to Station" :isTripsPage="false" :isStationPage="true" />
        <div v-if="$isSmallScreen">
            <div style="position: relative;" :class="'station-banner' + ($isRotatedMobile ? ' rotated': '')">
                <div :class="'njt-icon' + (($isMidTablet || $isBorderlineTablet) ? ' large-tablet-icon' : '')" @click="redirectToLanding"></div>

                <div v-if="!$isSignMode && ($isMidTablet || $isBorderlineTablet)" class="back-button-container">
                    <v-btn
                        elevation="2"
                        @click="this.navigateBackHome"
                    >
                        <span class="back-button">
                            HOME
                        </span>
                    </v-btn>
                </div>

                <div class="banner-message">
                    Station To Station
                </div>
            </div>


            <div class="fixed-container" v-if="hasQuery">
                <div class="plan-another-button">
                    <v-btn
                        @click="goBackWithoutQuery"
                    >
                    Plan Another Trip
                    </v-btn>
                </div>

                <div v-if="hasQuery && !loaded">
                    <Loader />
                </div>

                <div v-if="loaded && trains.length === 0" class="no-trip-message">
                    <a href="https://www.njtransit.com/dv-to?o=1&_format=json&hdnPageAction=TrainSchedulesTo">
                        No departures within 3hrs, tap here for trip plan.
                    </a>
                </div>
                
                <StationToStationDisplay
                    v-for="(train, index) in trains"
                    :key="index"
                    :leg="train"
                    :isFirst="index === 0"
                    :isLast="index === trains.length-1"
                    @navigate-to-stops="navigateToStops"
                />
                <div class="fare-button">
                    <v-btn
                        v-if="fare"
                        rounded
                    >
                        Adult Fare: {{fare}}
                    </v-btn>
                </div>
            </div>
            <div v-else class="small fixed-container">
                <div class="form-container">
                    <DropDown 
                        defaultValue="Select Origin"
                        :waiting="locations.length === 0"
                        :value="from"
                        valueField="bus_terminal_code"
                        displayField="bus_terminal_name"
                        :items="locations"
                        @onChange="this.setFrom"
                    />
                    <DropDown 
                        defaultValue="Select Destination"
                        :waiting="locations.length === 0"
                        :value="to"
                        valueField="bus_terminal_code"
                        displayField="bus_terminal_name"
                        :items="locations"
                        @onChange="this.setTo"
                    />
                    <v-btn
                        rounded
                        @click="this.redirectWithQuery"
                    >
                        Plan Trip
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="station-main" v-else>
            <div class="form-container">
                <div class="landscape-dropdowns">
                    <DropDown 
                        defaultValue="Select Origin"
                        :waiting="locations.length === 0"
                        :value="from"
                        valueField="bus_terminal_code"
                        displayField="bus_terminal_name"
                        :items="locations"
                        @onChange="this.setFrom"
                    />

                    <DropDown 
                        defaultValue="Select Destination"
                        :waiting="locations.length === 0"
                        :value="to"
                        valueField="bus_terminal_code"
                        displayField="bus_terminal_name"
                        :items="locations"
                        @onChange="this.setTo"
                    />
                </div>

                <v-btn
                    rounded
                    @click="this.redirectWithQuery"
                >
                    Plan Trip
                </v-btn>
            </div>

            <div v-if="hasQuery && !loaded">
                <Loader />
            </div>

            <div v-if="loaded && trains.length === 0" class="no-trip-message">
                <a href="https://www.njtransit.com/dv-to?o=1&_format=json&hdnPageAction=TrainSchedulesTo">
                    No departures within 3hrs, tap here for trip plan.
                </a>
            </div>

            <StationToStationDisplay
                v-for="(train, index) in trains"
                :key="index"
                :leg="train"
                :isFirst="index === 0"
                :isLast="index === trains.length-1"
                @navigate-to-stops="navigateToStops"
            />

            <div class="fare-button">
                <v-btn
                    v-if="fare"
                    rounded
                >
                    Adult Fare: {{fare}}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import RailRequests from "@/requests/RailRequests";
    import StationToStationDisplay from "@/data_display/StationToStationDisplay"
    import LaptopNavBar from "@/components/LaptopNavBar";
    import DropDown from "@/components/DropDown";
    import Loader from "@/components/Loader";

    import { executeKillSwitch } from "@/requests/BaseRequestValues";

    const __SELECTED_DEFAULT_PAGE_KEY__ = "__SELECTED_DEFAULT_PAGE_KEY__";

    export default {
        name: "StationToStation",
        components : {
            StationToStationDisplay,
            LaptopNavBar,
            DropDown,
            Loader
        },
        data() {
            return {
                locations: [],
                trains: [],
                fare: "",
                fromLocation: "",
                toLocation: "",
                hasQuery: false,
                from: null,
                to: null,
                loaded: false
            }
        },
        mounted() {

            this.loadLocations();

            if(this.$route.query.GetTripFrom && this.$route.query.To){
                this.hasQuery = true;
                this.from = this.$route.query.GetTripFrom;
                this.to = this.$route.query.To;
            }
            else {
                return;
            }

            if(this.$route.query.noLoad){
                this.hasQuery = false;
                return;
            }
            this.getData();
        },
        destroyed() {
            console.log("DESTROY STATION");
            executeKillSwitch();
        },
        methods: {
            navigateBackHome(evt){
                evt.preventDefault();
                this.$router.push({
                    path: '/'
                })
            },
            getData() {
                this.loaded = false;
                const params = {to: this.to, from: this.from};

                RailRequests.getStationToStation(params)
                .then(({data}) => {
                    console.log("STATION TO STATION DATA", data);
                    this.fare = data.REGULARFARE;

                    if(data.TRIP){
                        this.trains = data.TRIP[0].LEGS;
                    }
                    else{
                        this.trains = [];
                    }

                    this.loaded = true;
                    // this.trains = [
                    //     {
                    //         "STATION_2CHAR_ON": "NP",
                    //         "STATION_NAME_ON": "Newark Penn",
                    //         "SCHED_DEP_DATE": "11/29/2022 03:13 PM",
                    //         "STATION_2CHAR_OFF": "NY",
                    //         "STATION_NAME_OFF": "New York",
                    //         "SCHED_DEP_DATE_OFF": "11/29/2022 03:34 PM",
                    //         "TRAIN_ID": "3252",
                    //         "CURRENT_TRACK": "1",
                    //         "STATUS": "in 13 Min",
                    //         "SEC_LATE": "0",
                    //         "CURRENT_STATUS": "OnTime",
                    //         "STOPPING_AT": "Secaucus,Penn Station New York",
                    //         "TRAIN_LINE": "North Jersey Coast Line",
                    //         "STATION_POSITION": "1",
                    //         "DESTINATION": "Penn Station New York",
                    //         "INLINEMSG": "",
                    //         "BACKCOLOR": "black",
                    //         "FORECOLOR": "white",
                    //         "SHADOWCOLOR": "",
                    //         "LINECODE": "NC",
                    //         "LINEABBREVIATION": "NJCL"
                    //     }
                    // ];
                })
            },
            loadLocations() {
                RailRequests.getRailStations()
                .then(({data}) => {
                    this.locations = data;
                });
            },
            setFrom(val){
                this.from = val;
            },
            setTo(val) {
                this.to = val;
            },
            redirectWithQuery() {
                if(this.to && this.from){
                    if(this.loaded){
                        this.getData();
                        return;
                    }
                    this.$router.push({
                        path: "Station", 
                        query: {
                            GetTripFrom: this.from,
                            To: this.to
                        }
                    });
                }
            },
            goBackWithoutQuery() {
                this.$router.push({
                    path: "Station",
                    query: {
                        GetTripFrom: this.from,
                        To: this.to,
                        noLoad: true
                    }
                });
            },
            redirectToLanding() {
                // need to remove values from local storage?
                localStorage.removeItem(__SELECTED_DEFAULT_PAGE_KEY__);

                this.$router.push({
                    path: `/`
                });
            },
            navigateToStops(id) {
                console.log("STATION DISPLAY ROUTE", this.$route);
                this.$router.push({
                    path: "stops",
                    query: {
                        station: "XX",
                        tripid: id,
                        mode: "RAIL",
                        fromPlanner: "true",
                        from: this.from,
                        to: this.to
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .station-main {
        position: fixed;
        top: 10vh;
    }

    .form-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        margin-right: 5vw;
    }

    .small .form-container {
        flex-direction: column;
        margin-top: 15vh;
        gap: 5vh;
        margin-right: 0;
    }

    .form-container .dropdown-container {
        width: 80%;
    }

    .fixed-container {
        position: fixed;
        width: 100vw;
    }

    .landscape-dropdowns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        flex-grow: 1;
    }

    .plan-another-button {
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: flex-start;
        margin: 1rem;
    }

    .plan-another-button .v-btn {
        /* width: 10rem; */
    }

    .plan-another-button .theme--light.v-btn.v-btn--has-bg:not(.v-btn--disabled){
        max-width: none;
    }

    .fare-button {
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
    }

    .mobile .fare-button .v-btn {
        max-width: 70vw;
    }

    .station-banner {
        position: relative;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        color: white;
        height: calc(10 * var(--mvh));
        /* padding: 0.5rem 1rem; */
        box-shadow: 0 0 10px var(--primary-color);

        display: grid;
        grid-template-columns: 20vw 80vw;
    }

    .mobile .station-banner {
        padding: 0;
    }

    .banner-message {
        position: absolute;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100vw;
        padding-left: 1rem;

        pointer-events: none;
    }

    .njt-icon {
        content: "";
        /* background: url("../assets/njt_logo_white.svg"); */
        background: url("../assets/hug-icon-s-white.svg");
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 1rem;
        width: 2rem;
        height: 2.5rem;
        cursor: pointer;
    }

    .mobile .njt-icon {
        width: 2.9rem;
        height: 3.6rem;
    }

    .no-trip-message {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(187, 12, 12);
        padding: 0.25rem;
    }

    .no-trip-message a {
        text-decoration: none;
        color: white;
        font-size: 1.5rem;
    }


    .large-tablet-icon {
        position: relative;
    }

    .large-tablet-icon::after {
        content: "";
        position: absolute;
        background-color: white;
        height: 100%;
        width: 2px;
        top: 0%;
        right: -1rem;
    }

    .back-button-container {
        position: absolute;
        left: 6rem;
    }

</style>