<template>
    <div>
        {{ allString }}
    </div>
</template>

<script>

    import RailRequests from "@/requests/RailRequests";

    export default {
        name: "PathInfo",
        data(){
            return {
                allString: ""
            };
        },
        watch: {
            allString(newVal, oldVal) {
                console.log("GET THE INFO", newVal, oldVal);
            }
        },
        mounted() {
            console.log("GET THE INFO?");

            RailRequests.getAllLineMapInfo()
            .then(({data}) => {
                console.log("THE INFO DATA", data);
                this.allString = JSON.stringify(data);
            });
        }
    };
</script>
