
Just show the text of the current trips message

<template>
    <!-- <span v-if="this.$props.message && !Array.isArray(this.$props.message)" class="message">
        {{this.$props.message}}
        <div class="message-bell">
            <i class="fas fa-bell"></i>
        </div>
    </span> -->
    <div class="message-container">
        <div v-for="m in messages" :key="m" class="message list">
            {{ m }}
            <div class="message-bell">
                <i class="fas fa-bell"></i>
            </div>
        </div>
        <div v-if="$isDisconnected" class="message disconnected">
            Data connection temporarily lost, scheduled information only. Please visit <a href="www.njtransit.com">www.njtransit.com</a> for updates.
            <div class="message-bell">
                <i class="fas fa-bell"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MessageDisplay",
        props: ["message"],
        data() {
            return {
                messages: []
            }
        },
        watch: {
            message(newVal, oldVal){
                console.log("MESSAGE INNER DISPLAY WATCH", oldVal, "AA", newVal);
                this.setMessages(newVal);
            }
        },
        mounted() {
            console.log("MESSAGE INNER HERE", this.$props.message)
            this.setMessages(this.$props.message);
        },
        methods: {
            setMessages(m) {
                console.log("SET MESSAGES", m);

                if(m){
                    let messages = m;

                    if(!Array.isArray(m)){
                        messages = [m];
                    }

                    const copy = [];

                    // make sure we have actual messages
                    messages.forEach((e) => {
                        console.log("INDIVIDUAL MESSAGE", e);
                        let message = e;
                        if(Object.prototype.hasOwnProperty.call(e, "message")){
                            message = e.message;
                        }

                        if(message !== ""){
                            copy.push(message);
                        }
                    });

                    this.messages = copy;
                }
            }
        }
    }
</script>

<style scoped>
    .message {
        display: flex;
        position: relative;
        width: 98%;
        /* justify-content: center; */
        align-items: center;
        padding: calc(2 * var(--mvh)) 0.5rem;
        /* padding-left: calc(2rem + 2 * var(--mvh));
        font-weight: 600;
        font-size: 2rem;
        letter-spacing: 0.04rem;
        line-height: 1.5;
        border: 0.2rem solid black;
        border-radius: 1.5rem; */
        padding-left: calc(2.5rem + var(--mvh)*2);
        font-weight: 500;
        font-size: 2.15rem;
        letter-spacing: .04rem;
        line-height: 1.5;
        border: 0.2rem solid #999;
        border-radius: 1.25rem;
        background: #eee;
        box-shadow: 0 0 0.5rem rgba(0,0,0,0.2);
        margin: calc(0.9 * var(--mvh)) 0.9vw;
    }

    .disconnected.message {
        background: black;
        color: white;
        border: 0.2rem solid red;
    }

    .message-bell {
        position: absolute;
        left: 0.7rem;
        top: calc(2 * var(--mvh) + 0.1rem);
        font-size: 2rem;
        color: rgba(230, 100, 0, 0.7);
    }

    .disconnected .message-bell {
        color: red;
    }

    .message-container {
        display: flex;
        flex-direction: column;
        width: 100%
    }

    /* .list {
        justify-content: start;
        margin-left: 3rem;
    } */
</style>