<template>
    <div :class="'svg-container ' + (rotate ? 'rotated ' : '') + (mobile ? 'mobile' : '')">
        <svg data-v-349c2134="" height="40" width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zm0-448c110.5 0 200 89.5 200 200s-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56zm20 328h-40c-6.6 0-12-5.4-12-12V256h-67c-10.7 0-16-12.9-8.5-20.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v116c0 6.6-5.4 12-12 12z">
            </path>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "ExpandedArrowSVGIcon",
        props: ["rotate", "mobile"]
    }
</script>

<style scoped>
    .svg-container {
        background-color: transparent;
        transform:  translateY(-1rem);
    }

    .mobile {
        transform: translateY(0);
    }

    .rotated {
        transform: rotate(90deg) translateY(0);
        margin-left: 1rem;
    }
</style>