var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:(_vm.$isSmallScreen ? 'mobile' : '')},[(!_vm.$isSmallScreen)?_c('LaptopNavBar',{attrs:{"condensed":true,"pageType":_vm.pageType,"query":_vm.query,"hideText":true,"tripObj":"tripObj"}}):_vm._e(),_c('div',{class:'full-nav' + ((!_vm.$isSmallScreen) ? ' collapsed-nav' : '') + (_vm.$isRotatedMobile ? ' rotated' : '')},[(_vm.$isMidTablet || _vm.$isBorderlineTablet)?_c('div',{staticClass:"njt-icon",on:{"click":_vm.redirectToLanding}}):_vm._e(),_c('div',{class:'location-button' + ((_vm.$isMidTablet || _vm.$isBorderlineTablet) ? ' large-tablet-button' : '')},[(true || _vm.hasStopOrStation)?_c('v-btn',{attrs:{"elevation":"2"},on:{"click":this.navigateBack}},[_c('span',{staticClass:"one-line"},[_vm._v(" BACK ")])]):_vm._e()],1),(!_vm.hasStopOrStation)?_c('div',{staticClass:"f1",style:(`
                display: flex; 
                align-items: center; 
                ${_vm.$isSmallScreen ? 'justify-content: flex-end;' : 'justify-content: space-betwen;'} 
                width: 100%; 
                margin-left: 1rem;
                ${(_vm.$data.query.fromMap || _vm.$data.query.fromHomeNearby) ? '' : 'margin-top: 1vw'};
            `)},[(!_vm.$isSmallScreen)?_c('div',[(_vm.pageType === 'rail')?_c('div',{style:(`width: ${_vm.$isMobile ? '12vw' : (_vm.$data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                    height: ${_vm.$isMobile ? '12vw' : (_vm.$data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                    background-image: url(./rail_icons/${_vm.tripObj.route}_icon.png); 
                    background-size: contain; 
                    background-position: center center;`)}):_vm._e(),(_vm.pageType === 'rail' && !_vm.$data.query.fromMap)?_c('div',{staticClass:"rail-name f2",style:(`
                    ${_vm.$data.query.fromMap ? 'margin-right: 1rem;' : ''}
                    ${_vm.$isSmallScreen ? 'background: rgba(0,0,0,0.5); padding: 0.25rem; border-radius: 0.5rem; color: white;' : ''}
                    ${_vm.$isSmallScreen ? 'margin-left: 7vw;' : ''}
                `)},[_vm._v(" "+_vm._s(_vm.railLineName)+" ")]):(_vm.pageType !== 'rail')?_c('div',{staticClass:"route-number",style:(`
                    width: 3.5rem;
                    height: 3.5rem;
                    ${_vm.$isSmallScreen ? 'margin-left: 7vw;' : 'margin-right: 1rem;'}
                `)},[_vm._v(" "+_vm._s(_vm.tripObj.route)+" ")]):_vm._e()]):_vm._e(),(_vm.$data.query.fromMap/* || $data.query.fromHomeNearby*/)?_c('div',{staticClass:"vehicle-id"},[_vm._v(" "+_vm._s(_vm.pageType === "rail" ? "Train " : "")+" "+_vm._s(_vm.pageType === "lightrail" ? ((_vm.$data.query.fromNearby || _vm.$data.query.fromHomeNearby) ? "Bus " : "Train ") : _vm.remove0Num(_vm.$data.query.vid))+" to "+_vm._s(_vm.destination)+" ")]):(_vm.tripObj.vehicle_id)?_c('div',{staticClass:"vehicle-id"},[_vm._v(" "+_vm._s(_vm.pageType === "rail" ? "Train " : "")+" "+_vm._s(_vm.pageType === "lightrail" ? ((_vm.$data.query.fromNearby || _vm.$data.query.fromHomeNearby) ? "Bus " : "Train ") : _vm.remove0Num(_vm.tripObj.vehicle_id))+" to "+_vm._s(_vm.destination)+" ")]):(_vm.$data.query.tripid)?_c('div',{staticClass:"vehicle-id"},[_vm._v(" "+_vm._s(_vm.pageType === "rail" ? "Train " : "")+" "+_vm._s(_vm.pageType === "lightrail" ? ((_vm.$data.query.fromNearby || _vm.$data.query.fromHomeNearby) ? "Bus " : "Train ") : _vm.remove0Num(_vm.$data.query.tripid))+" to "+_vm._s(_vm.destination)+" ")]):_vm._e(),(_vm.$isSmallScreen)?_c('div',[(_vm.pageType === 'rail' && false)?_c('div',{style:(`width: ${_vm.$isMobile ? '12vw' : (_vm.$data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                        height: ${_vm.$isMobile ? '12vw' : (_vm.$data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                        background-image: url(./rail_icons/${_vm.tripObj.route}_icon.png); 
                        background-size: contain; 
                        background-position: center center;`)}):_c('div',{style:(`width: ${_vm.$isMobile ? '12vw' : (_vm.$data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                        height: ${_vm.$isMobile ? '12vw' : (_vm.$data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                        background-image: url(./images/map_icon.png); 
                        background-size: contain; 
                        background-position: center center;`)}),(_vm.pageType === 'rail' && !_vm.$data.query.fromMap)?_c('div',{staticClass:"rail-name",style:(`
                        ${_vm.$data.query.fromMap ? 'margin-right: 1rem;' : ''}
                        ${_vm.$isSmallScreen ? 'background: rgba(0,0,0,0.5); padding: 0.25rem; border-radius: 0.5rem; color: white;' : ''}
                        ${_vm.$isSmallScreen ? 'margin-left: 7vw;' : ''}
                    `)},[_vm._v(" "+_vm._s(_vm.railLineName)+" ")]):(_vm.pageType !== 'rail')?_c('div',{staticClass:"route-number",style:(`
                        width: 3.5rem;
                        height: 3.5rem;
                        ${_vm.$isSmallScreen ? 'margin-left: 7vw;' : ''}
                    `)},[_vm._v(" "+_vm._s(_vm.tripObj.route)+" ")]):_vm._e()]):_vm._e()]):_vm._e(),(!_vm.$data.query.fromMap /*&& !$data.query.fromHomeNearby*/)?_c('div',{staticClass:"f3",style:(`display: flex;
                flex-direction: row;
                align-items: center;
                ${_vm.$isSmallScreen ? 'justify-content: flex-end;' : ''}
                margin-left: 1rem;
                ${_vm.$isSmallScreen ? 'padding-left: 7vw;' : ''}
                `)},[(!_vm.$isSmallScreen)?_c('div',[(_vm.pageType === 'rail')?_c('div',{class:'route-number' + (_vm.pageType === 'rail' ? ' rail-button' : ''),style:(`background-image: url(./rail_icons/${_vm.tripObj.route}_icon.png); background-size: contain; background-position: center;`)}):_c('div',{staticClass:"route-number"},[_vm._v(" "+_vm._s(_vm.tripObj.route)+" ")])]):_vm._e(),_c('div',{staticClass:"vehicle-id padded"},[_vm._v(" "+_vm._s(_vm.pageType === "rail" ? "Train " : "")+" "+_vm._s(_vm.pageType === "lightrail" ? ((_vm.$data.query.fromNearby || _vm.$data.query.fromHomeNearby) ? "Bus " : "Train ") : _vm.remove0Num(((_vm.pageType === 'rail') ? _vm.$data.query.tripid : (_vm.tripObj ? _vm.tripObj.vehicle_id : ""))))+" to "+_vm._s(_vm.destination)+" ")]),(_vm.$isSmallScreen)?_c('div',[(_vm.pageType === 'rail')?_c('div',{class:'route-number' + (_vm.pageType === 'rail' ? ' rail-button' : ''),style:(`border: none; background-image: url(./images/map_icon.png); background-size: contain; background-position: center; cursor: pointer;`),on:{"click":_vm.navigateToMapPath}}):_c('div',{staticClass:"route-number"},[_vm._v(" "+_vm._s(_vm.tripObj.route)+" ")])]):(_vm.pageType === 'rail')?_c('div',{class:'route-number' + (_vm.pageType === 'rail' ? ' rail-button' : ''),style:(`border: none; background-image: url(./images/map_icon.png); background-size: contain; background-position: center; cursor: pointer;`),on:{"click":_vm.navigateToMapPath}}):_vm._e()]):_vm._e()]),_c('StopsContainer',{attrs:{"query":_vm.$data.query,"overflow":_vm.overflow,"mainDisplayHeightReduction":_vm.$props.mainDisplayHeightReduction,"tripObj":_vm.tripObj},on:{"setTripObj":_vm.setTripObj,"setDestination":_vm.setDestination}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }