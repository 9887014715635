
Shell for the StopsContainer component, which handles the 
query parameters, resizing, and navigation

Uses the vuetify v-btn component for a bit of animation


:style="{fontSize: appliedFontSize + 'vh'}"

<template>
    <div 
        :class="($isSmallScreen ? 'mobile' : '')"
    >
        <LaptopNavBar v-if="!$isSmallScreen" :condensed="true" :pageType="pageType" :query="query" :hideText="true" tripObj="tripObj" />
        <div :class="'full-nav' + ((!$isSmallScreen) ? ' collapsed-nav' : '') + ($isRotatedMobile ? ' rotated' : '')">

            <div v-if="$isMidTablet || $isBorderlineTablet" class="njt-icon" @click="redirectToLanding"></div>

            <div :class="'location-button' + (($isMidTablet || $isBorderlineTablet) ? ' large-tablet-button' : '')">
                <v-btn
                    v-if="true || hasStopOrStation"
                    elevation="2"
                    @click="this.navigateBack"
                >
                    <span class="one-line">
                        <!-- {{($isSmallScreen || locationName === " " || locationName === "STOP # ") ? "BACK" : locationName}} -->
                        BACK
                    </span>
                </v-btn>
            </div>
            
            <div v-if="!hasStopOrStation"
                class="f1"
                :style="`
                    display: flex; 
                    align-items: center; 
                    ${$isSmallScreen ? 'justify-content: flex-end;' : 'justify-content: space-betwen;'} 
                    width: 100%; 
                    margin-left: 1rem;
                    ${($data.query.fromMap || $data.query.fromHomeNearby) ? '' : 'margin-top: 1vw'};
                `"
            >

                <div v-if="!$isSmallScreen">
                <div v-if="pageType === 'rail'" 
                    :style="`width: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                        height: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                        background-image: url(./rail_icons/${tripObj.route}_icon.png); 
                        background-size: contain; 
                        background-position: center center;`"
                >
                </div>

                <div v-if="pageType === 'rail' && !$data.query.fromMap"
                    class="rail-name f2"
                    :style="`
                        ${$data.query.fromMap ? 'margin-right: 1rem;' : ''}
                        ${$isSmallScreen ? 'background: rgba(0,0,0,0.5); padding: 0.25rem; border-radius: 0.5rem; color: white;' : ''}
                        ${$isSmallScreen ? 'margin-left: 7vw;' : ''}
                    `"
                >
                    {{railLineName}}
                </div>
                <div v-else-if="pageType !== 'rail'" class="route-number"
                    :style="`
                        width: 3.5rem;
                        height: 3.5rem;
                        ${$isSmallScreen ? 'margin-left: 7vw;' : 'margin-right: 1rem;'}
                    `"
                >
                    {{tripObj.route}}
                </div>
                </div>

                <!-- <div v-if="$data.query.fromMap" style="margin-right: 3vw;">
                    <MapButton :withLabel="false" :usingGeolocation="true" />
                </div> -->

                <div class="vehicle-id" v-if="$data.query.fromMap/* || $data.query.fromHomeNearby*/">
                    {{ 
                        pageType === "rail"
                        ? "Train "
                        : ""
                    }}
                    {{
                        pageType === "lightrail"
                        ? (($data.query.fromNearby || $data.query.fromHomeNearby) ? "Bus " : "Train ")
                        : remove0Num($data.query.vid)
                    }}
                    to
                    {{destination}}
                </div>
                <div class="vehicle-id" v-else-if="tripObj.vehicle_id">
                    {{ 
                        pageType === "rail"
                        ? "Train "
                        : ""
                    }}
                    {{
                        pageType === "lightrail"
                        ? (($data.query.fromNearby || $data.query.fromHomeNearby) ? "Bus " : "Train ")
                        : remove0Num(tripObj.vehicle_id)
                    }}
                    to
                    {{destination}}
                </div>
                <div class="vehicle-id" v-else-if="$data.query.tripid">
                    <!-- #{{$data.query.tripid}} -->
                    {{ 
                        pageType === "rail"
                        ? "Train "
                        : ""
                    }}
                    {{
                        pageType === "lightrail"
                        ? (($data.query.fromNearby || $data.query.fromHomeNearby) ? "Bus " : "Train ")
                        : remove0Num($data.query.tripid)
                    }}
                    to
                    {{destination}}
                </div>

                <div v-if="$isSmallScreen">
                    <div v-if="pageType === 'rail' && false" 
                        :style="`width: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                            height: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                            background-image: url(./rail_icons/${tripObj.route}_icon.png); 
                            background-size: contain; 
                            background-position: center center;`"
                    >
                    </div>
                    <div v-else 
                        :style="`width: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                            height: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                            background-image: url(./images/map_icon.png); 
                            background-size: contain; 
                            background-position: center center;`"
                    >
                    </div>
                    

                    <div v-if="pageType === 'rail' && !$data.query.fromMap"
                        class="rail-name"
                        :style="`
                            ${$data.query.fromMap ? 'margin-right: 1rem;' : ''}
                            ${$isSmallScreen ? 'background: rgba(0,0,0,0.5); padding: 0.25rem; border-radius: 0.5rem; color: white;' : ''}
                            ${$isSmallScreen ? 'margin-left: 7vw;' : ''}
                        `"
                    >
                        {{railLineName}}
                    </div>
                    <div v-else-if="pageType !== 'rail'" class="route-number"
                        :style="`
                            width: 3.5rem;
                            height: 3.5rem;
                            ${$isSmallScreen ? 'margin-left: 7vw;' : ''}
                        `"
                    >
                        {{tripObj.route}}
                    </div>
                </div>
            </div>

            <div 
                v-if="!$data.query.fromMap /*&& !$data.query.fromHomeNearby*/"
                class="f3"
                :style="`display: flex;
                    flex-direction: row;
                    align-items: center;
                    ${$isSmallScreen ? 'justify-content: flex-end;' : ''}
                    margin-left: 1rem;
                    ${$isSmallScreen ? 'padding-left: 7vw;' : ''}
                    `
            ">

                <div v-if="!$isSmallScreen">
                    <div v-if="pageType === 'rail'" 
                        :class="'route-number' + (pageType === 'rail' ? ' rail-button' : '')"
                        :style="`background-image: url(./rail_icons/${tripObj.route}_icon.png); background-size: contain; background-position: center;`"
                    >
                    </div>
                    <div v-else class="route-number">
                        {{tripObj.route}}
                    </div>
                </div>

                <div class="vehicle-id padded">
                    <!-- {{(($data.query.tripid && !tripObj) || tripObj.vehicle_id) ? "#" : ""}} -->
                    {{ 
                        pageType === "rail"
                        ? "Train "
                        : ""
                    }}
                    {{
                        pageType === "lightrail"
                        ? (($data.query.fromNearby || $data.query.fromHomeNearby) ? "Bus " : "Train ")
                        : remove0Num(((pageType === 'rail') ? $data.query.tripid : (tripObj ? tripObj.vehicle_id : "")))
                    }}
                    to
                    {{destination}}
                </div>

                <div v-if="$isSmallScreen">
                    <div v-if="pageType === 'rail'" 
                        :class="'route-number' + (pageType === 'rail' ? ' rail-button' : '')"
                        :style="`border: none; background-image: url(./images/map_icon.png); background-size: contain; background-position: center; cursor: pointer;`"
                        @click="navigateToMapPath"
                    ></div>
                    <div v-else class="route-number">
                        {{tripObj.route}}
                    </div>
                </div>
                <div v-else-if="pageType === 'rail'"
                        :class="'route-number' + (pageType === 'rail' ? ' rail-button' : '')"
                        :style="`border: none; background-image: url(./images/map_icon.png); background-size: contain; background-position: center; cursor: pointer;`"
                        @click="navigateToMapPath">
                </div>

            </div>
        </div>
        <StopsContainer 
            :query="$data.query" 
            :overflow="overflow" 
            :mainDisplayHeightReduction="$props.mainDisplayHeightReduction"
            :tripObj="tripObj"
            @setTripObj="setTripObj"
            @setDestination="setDestination"
        />
    </div>
</template>

<script>
    import StopsContainer from "../components/StopsContainer";
    import { getName } from "@/NameMappings";

    import { getLocations } from "@/requests/OptionRequests";
    import { setName } from "@/NameMappings";

    import LaptopNavBar from "@/components/LaptopNavBar";
    // import MapButton from "@/components/MapButton";

    import { executeKillSwitch } from "@/requests/BaseRequestValues";

    const BASE_STORAGE_KEY = "__-StopIDSelection-__";
    const __SELECTED_DEFAULT_PAGE_KEY__ = "__SELECTED_DEFAULT_PAGE_KEY__";

    import { 
        setQueryParameters, 
        onlyNonNullValues, 
        determineDefaultFontSize, 
        decryptObject,
        removeLead0 } from "@/Utilities";

    export default {
        name: "Stops",
        props: ["mainDisplayHeightReduction"],
        components: {
            StopsContainer,
            LaptopNavBar,
            // MapButton
        },
        data() {
            return {
                query: {
                    layout: "",
                    fontSize: "",
                    scrolling: "",
                    route: "",
                    direction: "",
                    stop: "",
                    station: "",
                    status: "",
                    hidenav: null,
                    tripid: "",
                    late: null,
                    footer: null,
                    fromMap: null,
                    fromHomeNearby: null,
                    vid: null,
                    dark: null,
                    fromPlanner: null,
                    from: null,
                    to: null,
                    fromNearby: null
                },
                overflow: false,
                appliedFontSize: null,
                tripObj: null,
                pageType: null,
                hasStopOrStation: false,
                railLineName: "",
                locationName: "",
                destination: ""
            }
        },
        watch: {
            destination(oldVal, newVal){
                console.log("SET DESTINATION WATCH", oldVal, newVal);
            }
        },
        created() {
            setQueryParameters(this);
            this.appliedFontSize = determineDefaultFontSize(this.query, this.$mq);

            this.setOverflow();

            window.addEventListener("resize", this.resizeHandler);

            console.log("STOPS QUERY PARAM", this.query.tripid, this.query, this.$route.query);

            const pageType = this.$route.path.split("/")[1];
            this.pageType = pageType;

            if(this.query.tripid && pageType !== "rail"){
                let obj = null;
                try{
                    const translated = decodeURIComponent(this.query.tripid);
                    console.log("INVALID TRANSLATED TRIP?", this.query.tripid, translated);
                    obj = decryptObject(translated);
                    console.log("DECRYPTED TRIP", obj);
                    this.tripObj = this.tripObjRouteFix(obj);
                }
                catch(ex){
                    alert("Invalid Stop URL");
                    console.log("INVALID STOP", ex, ex.message);
                    return;
                }
                console.log("INVALID OBJECT BACK?", obj);
            }
            else if(pageType === "rail"){
                this.tripObj = { tripid: this.query.tripid, route: this.query.route };
                console.log("BEFORE GET STOP NAME", this.query);
                this.railLineName = getName(this.query.station, "rail-line");
            }

            const loc = this.query.station || this.query.stop;

            console.log("DO WE HAVE A STATION?", loc, this.query);

            if(loc){
                this.hasStopOrStation = true;
            }

            // get locations
            const retrieved = this.retrieveSelectionsFromStorage();

            console.log("STATION RETRIEVED", retrieved);

            if(!retrieved){
                console.log("CHECK ON STATIONS");

                const params = {
                    mode: null
                };

                // "/bus", "/lightrail", "/riverline"
                switch(this.pageType){
                    case "bus":
                        params.mode = "BUS";
                        break;
                    case "lightrail":
                        params.mode = "NLR";
                        break;
                    case "riverline":
                        params.mode = "RL";
                        break;
                    case "rail":
                        params.mode = "RAIL";
                        this.isStop = false;
                        this.active_tab = 2;
                        this.showTabs = false;
                        break;
                }

                getLocations(params)
                .then(({data}) => {
                    console.log("GET STATION DATA YYY", data);
                    data.forEach(location => {
                        setName(location.bus_terminal_code, location.bus_terminal_name, this.pageType + "-stop");
                    });

                    this.locationName = getName(loc, `${pageType}-stop`) || loc;
                    console.log("LOCATIONNAME", this.locationName);
                });
            }
            else {
                console.log("DO WE HAVE THE STATIONS ALREADY?");
                this.locationName = getName(loc, `${pageType}-stop`) || loc;
                console.log("LOCATIONNAME", this.locationName);
            }
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeHandler);
            console.log("DESTROY STOPS");
            executeKillSwitch();
        },
        methods: {
            remove0Num(val) {
                let v = val;
                if(val && val.trim().toUpperCase() === "EMPTY"){
                    v = this.tripObj.route;
                    return v;
                }
                return "#" + removeLead0(v);
            },
            setDestination(dest){
                console.log("SET DESTINATION CAPTURE", dest);
                this.destination = dest;
            },
            redirectToLanding() {
                // need to remove values from local storage?
                localStorage.removeItem(__SELECTED_DEFAULT_PAGE_KEY__);

                this.$router.push({
                    path: `/`
                });
            },
            navigateBack(evt){
                evt.preventDefault();

                if(this.query.fromMap){
                    this.$router.push({
                        path: 'map'
                    });
                    return;
                }
                else if(this.query.fromPlanner){
                    this.$router.push({
                        path: "Station",
                        query: {
                            GetTripFrom: this.query.from,
                            To: this.query.to
                        }
                    });
                    return;
                }
                
                const nonTrip = {...this.$data.query};

                nonTrip.tripid = null;

                if(this.pageType !== "lightrail"){
                    // nonTrip.route = null;
                }

                console.log("LOCATIONNAME", "A"+this.locationName+"A");

                if(this.locationName === " " || this.locationName === "STOP # "){
                    this.$router.push({
                        path: 'home',
                        query: onlyNonNullValues(nonTrip) 
                    });
                }
                else{
                    this.$router.push({
                        path: 'trips',
                        query: onlyNonNullValues(nonTrip) 
                    });
                }

            },
            navigateBackHome(evt){
                evt.preventDefault();

                this.$data.query.route = null;
                this.$data.query.direction = null;
                this.$data.query.stop = null;
                this.$data.query.station = null;
                this.$data.query.tripid = null;

                this.$router.push({
                    path: `/${this.pageType}/home/`,
                    query: onlyNonNullValues(this.$data.query) 
                });
            },
            navigateToDepartures(evt) {
                evt.preventDefault();

                const nonTripOrStop = {...this.$data.query};
                nonTripOrStop.tripid = null;
                nonTripOrStop.route = this.tripObj.route;

                console.log("NAVIGATE TO DEPARTURES", nonTripOrStop);

                this.$router.push({
                    path: 'trips',
                    query: onlyNonNullValues(nonTripOrStop) 
                });
            },
            navigateToMapPath(evt){
                // how do I get the route?
                evt.preventDefault();

                const nonTripOrStop = {...this.$data.query};
                nonTripOrStop.tripid = null;
                nonTripOrStop.linedisplay = this.tripObj.route;
                nonTripOrStop.station = this.$data.query.station;
                nonTripOrStop.tripid = this.$data.query.tripid;
    
                console.log("NAVIGATE TO DEPARTURES", nonTripOrStop);
    
                this.$router.push({
                    path: 'map',
                    query: onlyNonNullValues(nonTripOrStop) 
                });
            },
            setOverflow(){
                this.overflow = this.$isMobile || this.query.scrolling === 0;
                // this.overflow = !((
                //     (this.$mq === 'large' && this.query.layout !== 'm') 
                //     || this.query.layout === 'l'
                // ) && this.query.scrolling != 0);
            },
            resizeHandler() {
                this.setOverflow();
            },
            setTripObj(obj) {
                console.log("SET TRIP OBJ", obj);
                // kind of a hack, but I'm not going to totally restructure this now...
                this.tripObj = this.tripObjRouteFix(obj);
                this.railLineName = getName(obj.route, "rail-line");
            },
            tripObjRouteFix(obj){
                if(this.pageType === "lightrail"){
                    if(this.query.route && this.query.route.toUpperCase() === "RL"){
                        obj.route = "RL";
                    }
                    else if(this.query.route && this.query.route.toUpperCase() === "HBLR"){
                        obj.route = "HB";
                    }
                    else {
                        obj.route = "NLR";
                    }
                }
                return obj;
            },
            retrieveSelectionsFromStorage(){
                const str = localStorage.getItem(BASE_STORAGE_KEY + this.pageType);
                if(str){
                    const obj = JSON.parse(str);
                    if(obj){

                        console.log("STOP ID SELECTION OBJECT ON LOAD", obj);

                        // set all the keys
                        Object.keys(obj).forEach(key => {
                            this[key] = obj[key];
                        });

                        if(this.active_tab == 1){
                            console.log("STOP ACTIVE TAB 1");
                        }
                        else{
                            console.log("STOP NOT ACTIVE TAB 1", this.active_tab);
                        }

                        return true;
                    }
                }
                return false;
            }
        }
    }
</script>

<style scoped>
    a {
        font-weight: normal !important;
    }

    .full-nav {
        position: relative;
        /* width: 70%; */
        gap: 2rem;
        /* padding-left: 6.5rem; */
        justify-content: flex-start;
        margin: 0 auto;
    }

    .collapsed-nav {
        position: absolute;
        top: calc(-0.5 * var(--mvh));
        left: 6.5rem;
    }

    .route-number {
        background-color: var(--primary-color);
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        width: min(4vw, calc(8 * var(--mvh)));
        height: min(4vw, calc(8 * var(--mvh)));

        border: 2px solid white;

        font-family: Roboto, sans-serif;
        font-weight: 500;
        /* width: 5vw;
        height: 5vw;
        font-size: 1.2rem; */

        font-size: 1.15rem;

        padding: 0.2vw;
        aspect-ratio: 1;
    }

    .rail-name {
        font-size: 1.7rem; 
        margin-left: 1rem;
    }

    .dark .rail-name {
        color: white;
    }

    .mobile .full-nav {
        /* width: 100%; */
        padding: 0 calc(1.2 * var(--mvh));
        /* width: 95%; */
        /* padding-left: 0; */
        gap: 0rem;
        justify-content: space-between;

        background-color: var(--primary-color);
        box-shadow: 0 0 10px var(--primary-color);

        overflow: hidden;
    }

    .mobile .full-nav.rotated .v-btn {
        transform: scale(0.9);
    }

    /* .mobile .full-nav::after {
        z-index: 0;
        content: "";
        position: absolute;
        background: url(/img/njt_logo_white.d1e61d80.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 20vh;
        height: 20vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: blur(1px);
        opacity: 0.1;
    } */

    .mobile .route-number {
        width: 8.5vh;
        height: 8.5vh;
        /* width: 12vw;
        height: 12vw; */
        font-size: 1.25rem;
        border: 0.2rem solid white;
    }

    .rail-button.route-number {
        /* width: initial;
        height: initial; */
        padding: 15px;
    }

    .location-button .v-btn {
        max-width: 60vw !important;
        z-index: 1;
    }

    .vehicle-id {
        font-size: 1.5rem; 
        margin: 0 0.8vw 0 0;
        max-width: 40vw;
    }

    .padded {
        color: white;
        padding-left: 1rem;
    }

    .mobile .vehicle-id {
        font-size: 1.15rem;
        color: white;
        margin: 0 0.25rem;
        /* max-width: 50vw; */

        padding: 0 !important;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: 60vw;
    }

    .dark .vehicle-id {
        color: white;
    }

    .njt-icon {
        background: url("../assets/hug-icon-s.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .smallscreen .njt-icon {
        position: relative;
        margin-left: 1rem;
        /* width: 3.5rem;
        height: 3.5rem; */
        width: calc(5.3 * var(--mvh));
        height: calc(6.6 * var(--mvh));
    }

    .smallscreen .njt-icon::after {
        content: "";
        position: absolute;
        background-color: white;
        height: 100%;
        width: 2px;
        top: 0%;
        right: -1rem;
    }

    .dark .njt-icon,
    .mobile .njt-icon,
    .smallscreen .njt-icon {
        background: url("../assets/hug-icon-s-white.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }
    .large-tablet-button {
        position: absolute;
        left: calc(5.3 * var(--mvh) + 5rem);
    }

    /* @media only screen and (max-width: 600px) {
        .full-nav {
            width: 100%;
        }

        .route-number {
            width: 9vw;
            height: 9vw;
            padding: 5px;
            font-size: 0.8rem;
        }
    } */
</style>