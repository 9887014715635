<template>
    <div v-if="show" class="audio">
        <div class="inner-audio-container">
            <div class="audio-message-scroller">
                <div class="audio-message" ref="message_display">
                    {{messageText}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getAudioMessage } from "@/requests/DataRequests";

    let interval = null;
    let scrollInterval = null;

    const REQUEST_PERIOD = 1000;

    export default {
        name: "AudioMessageDisplay",
        props: ["ip"],
        data: () => ({
            messageText: "",
            position: "",
            duration: "",
            show: false,
            pageType: "bus"
        }),
        mounted() {
            this.pageType = this.$route.path.split("/")[1];
            console.log("AUDIO MESSAGE MOUNTED", this.pageType);
            this.getMessage();
        },
        destroyed() {
            clearInterval(interval);
        },
        methods: {
            getMessage(){
                getAudioMessage(this.$props.ip, this.pageType)
                .then(({data}) => {
                    console.log("GOT AN AUDIO MESSAGE BACK", data);
                    if(data.enable_scrolling && !data.enable_full_screen){
                        this.setMessageText(data.message, data.duration);
                        interval = setTimeout(() => {
                            this.reset();
                            this.getMessage();
                        }, data.duration * REQUEST_PERIOD);
                    }
                    else {
                        this.reset();
                        interval = setTimeout(this.getMessage, REQUEST_PERIOD);
                    }
                });
            },
            setMessageText(text, duration){
                this.messageText = text;
                this.show = true;
                this.position = 0;
                this.duration = duration;

                scrollInterval = setInterval(() => {
                    this.position++;
                    if(this.$refs.message_display){
                        this.$refs.message_display.style.transform = `translateY(${-1 * this.position}px)`;
                    }
                }, 50);
            },
            reset() {
                this.messageText = "";
                this.position = 0;
                this.duration = null;
                this.show = false;
                clearTimeout(interval);
                clearInterval(scrollInterval);
            }
        }
    }
</script>

<style scoped>
    .audio {
        position: absolute;
        height: calc(30 * var(--mvh));
        width: 100vw;
        bottom: 0;
        left: 0;
        z-index: 100;
        box-shadow: 0 0 10vw rgba(0,0,0,0.8);
    }

    .inner-audio-container{
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;
    }

    .audio-message-scroller {
        position: absolute;
        top: 0;
        left: 0;
        background: black;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .audio-message {
        position: absolute;
        top: 50%;
        font-size: 3rem;
        color: white;
        padding: 4rem;
    }
</style>