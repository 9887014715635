
const RailDestinationDirection = {
    "AC":{
        "Eastbound": "right",
        "Westbound": "left"
    },
    "BC":{
        "Eastbound": "bottom",
        "Westbound": "top"
    },
    "GS":{
        "Eastbound": "right",
        "Westbound": "left"
    },
    "MC":{
        "Eastbound": "right",
        "Westbound": "left"
    },
    "ME":{
        "Eastbound": "right",
        "Westbound": "left"
    },
    "ML":{
        "Eastbound": "bottom",
        "Westbound": "top"
    },
    "NC":{
        "Eastbound": "top",
        "Westbound": "bottom"
    },
    "NE":{
        "Eastbound": "right",
        "Westbound": "left"
    },
    "PR":{}, // NOT APPLICABLE
    "PV":{
        "Eastbound": "bottom",
        "Westbound": "top"
    },
    "RV":{
        "Eastbound": "right",
        "Westbound": "left"
    }
};

export default RailDestinationDirection;