
NOT FULLY IMPLEMENTED YET

<template>
    <div class="map-container scroll-check" :key="geolocationDenied">
        <LaptopNavBar v-if="!$isSmallScreen" :condensed="true" :pageType="pageType" :query="query" :isMapPage="true" :lineDisplay="query.lineDisplay ? {station: query.station, tripid: query.tripid} : null" />

        <NJT_Title v-else singleLineOnly="true" :isMapPage="true" :lineDisplay="query.lineDisplay ? {station: query.station, tripid: query.tripid} : null" />

        <!-- <div v-if="currentPosition && currentPosition.lat" style="color: red;">
            {{currentPosition.lat}}, {{currentPosition.lng}}
        </div> -->

        <div :style="`width: 100vw; height: calc(${query.lineDisplay ? ($isSmallScreen ? 84 : 91): 65} * var(--mvh)); display: flex; justify-content:flex-start; ${($isSmallScreen) ? '' : ' position: absolute; top: calc(9 * var(--mvh));'}`">
            <MapDisplayTester
                :currentPosition="currentPosition" 
                :height="query.lineDisplay ? ($isSmallScreen ? 'calc(84 * var(--mvh))' : 'calc(91 * var(--mvh))') : 'calc(65 * var(--mvh))'"
                :pageType="pageType" 
                :usingGeolocation="usingGeolocation" 
                :geolocationDenied="geolocationDenied"
                :fullPage="true"
                :lineDisplay="query.lineDisplay"
                :tripid="query.tripid"
            />
        </div>

        <NearbyContainer 
            v-if="!query.lineDisplay"
            :usingGeolocation="usingGeolocation" 
            :currentPosition="currentPosition"
            :isHorizontal="!$isSmallScreen"
            @navigate-to-stops = "navigateToStops"
            @navigate-to-trips = "navigateToTrips"
        />
    </div>
</template>

<!--
<MapDisplay 
v-if="currentPosition"
:currentPosition="currentPosition" 
height="100vh" 
:pageType="pageType" 
:usingGeolocation="usingGeolocation" 
/>
-->

<script>
    import { setQueryParameters, onlyNonNullValues, determineDefaultFontSize, encryptTrip, decryptObject, minPositionDifference } from "@/Utilities";
    // import MapDisplay from "@/data_display/MapDisplay";
    import MapDisplayTester from "@/data_display/MapDisplayTester";

    import LaptopNavBar from "@/components/LaptopNavBar";
    import NJT_Title from "@/components/NJT_Title";
    import NearbyContainer from "@/components/NearbyContainer";

    import { executeKillSwitch } from "@/requests/BaseRequestValues";

    export default {
        name: "Map",
        components: {
            // MapDisplay
            MapDisplayTester,
            LaptopNavBar,
            NJT_Title,
            NearbyContainer
        },
        data(){
            return {
                query: {
                    layout: "",
                    fontSize: "",
                    scrolling: "",
                    route: "",
                    direction: "",
                    stop: "",
                    station: "",
                    status: "",
                    hidenav: null,
                    tripid: "",
                    late: null,
                    dark: null,
                    lineDisplay: null
                },
                usingGeolocation: false,
                geolocationDenied: false,
                currentPosition: null,
                pageType: "bus"
            }
        },
        watch: {
            query(newVal, oldVal) {
                console.log("DOES THE QUERY VALUE CHANGE?", newVal, oldVal);
            },
            geolocationDenied(newVal, oldVal) {
                console.log("GEO DENIED VALUE CHANGED?", newVal, oldVal);
            }
        },
        created() {
            setQueryParameters(this, true);
            this.appliedFontSize = determineDefaultFontSize(this.query, this.$mq);

            this.pageType = this.$route.path.split("/")[1];
            // this.setOverflow();

            if(navigator.permissions){
                navigator.permissions.query({name:'geolocation'})
                .then((permissionStatus) => {
                    console.log('geolocation permission state is ', permissionStatus.state);

                    this.processGeolocationState(permissionStatus);

                    permissionStatus.onchange = () => {
                        console.log('geolocation permission state has changed to ', this.state);
                        this.processGeolocationState(permissionStatus);
                    };
                })
                .catch(err => alert("permission query error: " + err.message));
            }
            else{
                this.watchPosition();
            }

            window.addEventListener("resize", this.resizeHandler);
        },
        mounted() {
            this.geolocate();
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeHandler);
            console.log("DESTROY MAP");
            executeKillSwitch();
        },
        methods: {
            navigateBackToTrips(evt){
                evt.preventDefault();
                
                const nonTrip = {...this.$data.query};
                nonTrip.tripid = null;

                this.$router.push({
                    path: 'trips',
                    query: onlyNonNullValues(nonTrip) 
                });
            },
            navigateToTrips(stopValue, route, direction, stopName, modeVal){

                console.log("NAVIGATE TO TRIPS FORM MAP", stopValue, stopName);

                if(this.pageType === "rail"){
                    this.query.station = stopValue;
                }
                else{
                    this.query.stop = stopValue;
                    this.query.stopName = stopName;
                }

                if(modeVal === "NLR" || modeVal === "RL" || modeVal === "HBLR"){
                    this.query.route = modeVal;
                }

                this.query.fromMap = true;

                console.log("NAVIGATE", this.query);

                this.$router.push({
                    path: `/${this.pageType}/trips`, 
                    query: onlyNonNullValues(this.$data.query)
                });
            },
            navigateToStops(trip) {
                console.log("MEMBER HOME NAVIGATE", trip);

                let valueToUse = null;
                let vehicleID = null;

                if(this.pageType === "rail"){
                    valueToUse = trip.VehicleID;
                    this.$data.query.mode = "RAIL";
                    vehicleID = trip.VehicleID;
                }
                else {
                    valueToUse = encryptTrip(trip);

                    console.log("ENCRYPTED VAUE TO USE FOR TRIP ID", trip, valueToUse);

                    const o = decryptObject(valueToUse);

                    console.log("DECRYPTED TRIP IMMEDIATELY", o);

                    vehicleID = trip.VehicleID;
                    // const params = {
                    //     internal_trip_number: trip.internal_trip_number,
                    //     sched_dep_time: trip.sched_dep_time,
                    //     timing_point_id: trip.timing_point_id,
                    //     route: trip.public_route || trip.linecode,
                    //     // iconStyle: this.iconStyle
                    // };

                    // // the only way to pas all of the information necessary for getting
                    // // the stops and maintaining proper future navigation to the 
                    // // "all trips" route is to have all of the above passed through
                    // // - to make this part of the URL, this object will be encrypted into a string
                    // //   and passed along in the querystring
                    // valueToUse = encryptObject(params);
                }

                console.log("NAVIGATE PATH", `/${this.pageType}/stops`);

                this.query.fromMap = true;
                this.query.vid = vehicleID;

                console.log("HAVE VEHICLE ID", trip, vehicleID, this.query.vid);

                this.$router.push({
                    path: `/${this.pageType}/stops`, 
                    query: onlyNonNullValues({...this.$data.query, tripid: valueToUse})
                });
            },
            geolocate: function() {
                console.log("GEOLOCATING");
                navigator.geolocation.getCurrentPosition(position => {
                    console.log("WE GOT A GEO POSITION", position);

                    if(position){
                        this.usingGeolocation = true;
                        this.currentPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                    }
                    else{
                        setTimeout(this.geolocate, 50);
                    }
                }, (err) => {
                    console.log("GEO LOCATING GET CURRENT ERROR", err);
                    if(err.code === 3){
                        this.geolocate();
                    }
                }, 
                {timeout: 1000, enableHighAccuracy: false, maximumAge:Infinity}
                );

                // setTimeout(()=> {
                //     if(!this.currentPosition){
                //         this.geolocate();
                //     }
                // }, 50);
            },
            watchPosition() {
                console.log("WATCHNG THE GEO POSITION");
                this.geolocate();

                const permissionDenied = () => {
                    // alert("MADE IT HERE 1");
                    this.usingGeolocation = false;
                    this.geolocationDenied = true;
                    // alert("MADE IT HERE 2");
                }

                navigator.geolocation.watchPosition((position) => {
                    console.log("WATCHING FOR NEW GEO POSITION", position);
                    this.usingGeolocation = true;
                    this.geolocationDenied = false;

                    let proceed = !this.currentPosition;
                    proceed |= minPositionDifference(position, this.currentPosition);

                    if(proceed){
                        this.currentPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                    }
                },
                function(error) {
                    if (error.code == error.PERMISSION_DENIED){
                        // alert("GEOLOCATION IS DENIED 1");
                        permissionDenied();
                    }
                });
            },
            processGeolocationState(permissionStatus) {
                console.log("GEOLOCATION PERMISSION?", permissionStatus);
                switch(permissionStatus.state) {
                    case "granted":
                        console.log("GEOLOCATION PERMISSION GRANTED");
                        this.usingGeolocation = true;
                        this.geolocationDenied = false;

                        console.log("GEO SET, DO WE START WATCHING?");

                        this.watchPosition();
                        // navigator.geolocation.getCurrentPosition(position => {
                        //     this.currentPosition = {
                        //         lat: position.coords.latitude,
                        //         lng: position.coords.longitude
                        //     };

                        //     console.log("POSITION SETTING", position, this.currentPosition);

                        //     console.log("Current Position", this.currentPosition);
                        // }, err => {console.log("GEOLOCATION TIMEOUT", err);}, {timeout: 100000, enableHighAccuracy: false});

                        // navigator.geolocation.watchPosition(p => {
                        //     const latDiff = Math.abs(p.coords.latitude - this.currentPosition.lat);
                        //     const lonDiff = Math.abs(p.coords.longitude - this.currentPosition.lng);

                        //     console.log("WATCH THE POSITION", p, this.currentPosition, latDiff, lonDiff);

                        //     const MIN_DIFF = 0.00002;

                        //     if(latDiff > MIN_DIFF || lonDiff > MIN_DIFF){
                        //         this.currentPosition = {
                        //             lat: p.coords.latitude,
                        //             lng: p.coords.longitude
                        //         };
                        //         console.log("CHANGE THE CURRENT POSITION", this.currentPosition);
                        //     }
                        // });

                        break;
                    case "denied":
                        this.usingGeolocation = false;
                        this.geolocationDenied = true;
                        // alert("GEOLOCATION IS DENIED 2");
                        break;
                    case "prompt":
                        this.watchPosition();
                        break;
                    default:
                        // treat as denied
                        this.usingGeolocation = false;
                        this.geolocationDenied = true;
                        // alert("GEOLOCATION IS DENIED 3");
                }
            }
        }
    }
</script>

<style scoped>

</style>