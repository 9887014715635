
Shell for the TripsContainer component, which handles the 
query parameters, resizing, and navigation

Show the selected station/stop based on the query parameter

:style="{fontSize: appliedFontSize + 'vh'}"

<template>
    <div>
        <div>
            <LaptopNavBar v-if="!$isSignMode && !$isSmallScreen" :condensed="true" :pageType="pageType" :query="query" :text="locationName" :isTripsPage="true" />
            <div ref="tripBanner" v-if="$isSignMode || $isSmallScreen" 
                :class="'location-banner' + (($data.query.hidemodeswitch || $isSignMode) && !$isMobile ? ' horizontal sign-mode' : '') + ($isRotatedMobile ? ' rotated': '') + (($isMidTablet || $isBorderlineTablet) ? ' larger-tablet' : '')"
            >

                <div class="njt-icon" @click="redirectToLanding"></div>

                <!-- grid placeholder -->
                <div v-if="!$isSignMode && ($isMidTablet || $isBorderlineTablet)" class="back-button-container">
                    <v-btn
                        elevation="2"
                        @click="this.navigateBackHome"
                    >
                        <span class="back-button">
                            BACK
                        </span>
                    </v-btn>
                </div>
                <div v-else></div>

                <div v-if="!$isSignMode" :class="($isMobile && locationName.length > 25) ? ((($isMobile && locationName.length > 25)) ? ' extra-large-name' : ' large-name' ) : ''"
                    style="
                            position: absolute; 
                            width: 75vw; 
                            text-align:center; 
                            left: 17vw;
                    "
                >
                    {{locationName}}
                </div>
                <div v-else-if="$isVeryLargeScreen" class="very-large-location-name">
                    <div>
                        {{ locationName }}
                    </div>
                    <div>
                        {{ locationNameSecond }}
                    </div>
                </div>
                <div v-else
                    style="
                            position: absolute; 
                            text-align:center; 
                            left: 50%;
                            transform: translate(-50%);
                            font-size: 1.7rem;
                        "
                >
                    {{locationName}}
                </div>

                <ClockDisplay v-if="($data.query.hidemodeswitch || $isSignMode) && !$isMobile" :horizontal="true" />

            </div>
            <TripsContainer 
                :locationName="justLocation"
                :query="$data.query"
                :overflow="overflow" 
                :mainDisplayHeightReduction="$data.query.showstation ? heightReduction + 10 : heightReduction" 
                @navigate-to-stops="this.navigateToStops" 
            />
        </div>
        <AudioMessageDisplay v-if="query.layout === 's' && query.ip" :ip="query.ip" />
    </div>
</template>

<script>
    import TripsContainer from "../components/TripsContainer";
    import ClockDisplay from "@/components/ClockDisplay";
    import { setQueryParameters, onlyNonNullValues, determineDefaultFontSize } from "@/Utilities";
    import { getName } from "@/NameMappings";

    import { getLocations } from "@/requests/OptionRequests";
    import { setName } from "@/NameMappings";

    import AudioMessageDisplay from "@/data_display/AudioMessageDisplay";

    import LaptopNavBar from "@/components/LaptopNavBar";

    import { executeKillSwitch } from "@/requests/BaseRequestValues";

    const BASE_STORAGE_KEY = "__-StopIDSelection-__";
    const __SELECTED_DEFAULT_PAGE_KEY__ = "__SELECTED_DEFAULT_PAGE_KEY__";

    export default {
        name: "Trips",
        props: ["mainDisplayHeightReduction"],
        components: {
            TripsContainer, 
            ClockDisplay,
            AudioMessageDisplay,
            LaptopNavBar
        },
        data() {
            return {
                query: {
                    layout: "",
                    fontSize: "",
                    scrolling: "",
                    route: "",
                    direction: "",
                    stop: "",
                    station: "",
                    rows: "",
                    status: "",
                    hidenav: null,
                    showstation: null,
                    ip: null,
                    sign: null,
                    late: null,
                    footer: null,
                    fromMap: null,
                    stopName: null,
                    fromHomeNearby: null,
                    dark: null,
                    shorten: null,
                    messages: null,
                    filtered: null,
                    paramid: null,
                    qr: null,
                    inStation: null
                },
                overflow: true,
                appliedFontSize: null, 
                heightReduction: 0,
                time: this.getCurrentTime(),
                timeIntervalId: null,
                locationName: "",
                justLocation: "",
                pageType: "",
                locationNameSecond: "",
                dimensions: ""
            }
        },
        watch: {
            dimensions(n, o){
                console.log('DIM', n, o);
            }
        },
        created() {
            setQueryParameters(this);

            this.dimensions = window.innerHeight + "__" + window.innerWidth;

            this.appliedFontSize = determineDefaultFontSize(this.query, this.$mq);

            this.setOverflow();

            const pageType = this.$route.path.split("/")[1];
            this.pageType = pageType;

            const loc = (this.$data.query.stop || this.$data.query.station);
            console.log("CURRENT TRIPS STATION", loc, this.query);
        
            if(this.query.late){
                this.locationName = "Late Trains";
            }
            else{
                const callback = (val) => {
                    console.log("CALLBACK FOR NAME 1", val);
                    const potentialName = val || loc;
                    if(potentialName && potentialName.trim().length){
                        this.locationName = potentialName;
                    }
                    else if(this.query.stopName){
                        this.locationName = decodeURIComponent(this.query.stopName);
                    }
                    this.appendToName();
                }

                const gn = getName(loc, `${pageType}-stop`, callback);

                console.log("DO WE GET STATION NAME HERE?", loc, gn, callback);

                if(gn){
                    callback(gn);
                }
                else if(this.query.stopName){
                    this.locationName = decodeURIComponent(this.query.stopName);
                    this.appendToName();
                }
            }

            this.heightReduction = this.$props.mainDisplayHeightReduction || 0;

            this.timeIntervalId = setInterval(() => {
                this.time = this.getCurrentTime();
            }, 1000);

            window.addEventListener("resize", this.resizeHandler);

            // get locations
            if(!this.retrieveSelectionsFromStorage()){
                console.log("CHECK ON STATIONS");

                const params = {
                    mode: null
                };

                // "/bus", "/lightrail", "/riverline"
                switch(this.pageType){
                    case "bus":
                        params.mode = "BUS";
                        break;
                    case "lightrail":
                        params.mode = "NLR";
                        break;
                    case "riverline":
                        params.mode = "RL";
                        break;
                    case "hblr": 
                        params.mode = "HBLR";
                        break;
                    case "rail":
                        params.mode = "RAIL";
                        this.isStop = false;
                        this.active_tab = 2;
                        this.showTabs = false;
                        break;
                }

                params.sign = this.query.sign;

                getLocations(params)
                .then(({data}) => {
                    console.log("GET STATION DATA YYY", data, loc);
                    data.forEach(location => {
                        setName(location.bus_terminal_code, location.bus_terminal_name, this.pageType + "-stop");
                    });

                    const callback = (val) => {
                        console.log("CALLBACK FOR NAME 2", val);
                        this.locationName = val || loc;
                        this.appendToName();
                    }

                    const gn = getName(loc, `${pageType}-stop`, callback);

                    console.log("GET STATION NAME", loc, gn, callback);

                    if(gn){
                        callback(gn);
                    }
                });
            }

            // setTimeout(() => {
            //     const main = this.$refs.tripBanner;
            //     console.log("TRIP MAIN ELEMENT", main);

            //     const val = document.createElement("div");
            //     val.classList.add("size-marker");

            //     const sizeDef = main.style.height;
            //     const sizeVal = main.offsetHeight;
            //     const sizeRect = main.getBoundingClientRect().height;

            //     const full = window.innerHeight;

            //     val.innerText = `H: ${sizeDef}:: ${sizeVal}:: ${sizeRect} // ${full}`;

            //     main.appendChild(val);
            // }, 300);
        },
        destroyed() {
            clearInterval(this.timeIntervalId);
            window.removeEventListener("resize", this.resizeHandler);
            console.log("DESTROY TRIPS");
            executeKillSwitch();
        },
        methods: {
            navigateToStops(tripid) {
                console.log("GO TO STOPS FOR", tripid, this.$data.query);

                this.$router.push({
                    path: 'stops', 
                    query: onlyNonNullValues({...this.$data.query, tripid}) 
                });
            },
            navigateBackHome(evt){
                evt.preventDefault();
                this.$router.push({
                    path: '/',
                    query: onlyNonNullValues(this.$data.query) 
                })
            },
            redirectToLanding() {
                // need to remove values from local storage?
                localStorage.removeItem(__SELECTED_DEFAULT_PAGE_KEY__);

                this.$router.push({
                    path: `/`
                });
            },
            setOverflow(){
                this.overflow = this.$isSmallScreen || !this.query.scrolling;
                console.log("DO WE HAVE OVERFLOW?", this.overflow, this.query.scrolling);
                // this.overflow = !((
                //     (this.$mq === 'large' && this.query.layout !== 'm') 
                //     || this.query.layout === 'l'
                // ) && this.query.scrolling != 0);
            },
            resizeHandler() {
                this.setOverflow();
            },
            getCurrentTime(){
                let now = new Date();
                let hour = now.getHours();
                let minutes = now.getMinutes();
                const AMPM = hour >= 12 ? "PM" : "AM";

                hour = hour > 12 ? hour - 12 : hour;
                minutes = (minutes+"").padStart(2, "0");

                now = `${hour}:${minutes} ${AMPM}`;
                return now;
            },
            retrieveSelectionsFromStorage(){
                const str = localStorage.getItem(BASE_STORAGE_KEY + this.pageType);
                if(str){
                    const obj = JSON.parse(str);
                    if(obj){

                        console.log("STOP ID SELECTION OBJECT ON LOAD", obj);

                        // set all the keys
                        Object.keys(obj).forEach(key => {
                            this[key] = obj[key];
                        });

                        if(this.active_tab == 1){
                            console.log("STOP ACTIVE TAB 1");
                        }
                        else{
                            console.log("STOP NOT ACTIVE TAB 1", this.active_tab);
                        }

                        return true;
                    }
                }
                return false;
            },
            appendToName(){
                if(
                    this.locationName.indexOf("Departures") === -1
                    && this.locationName.indexOf("Arrivals") === -1
                ){
                    this.justLocation = this.locationName;

                    if(this.$isVeryLargeScreen){
                        if(this.pageType !== "rail"){
                            this.locationNameSecond = " Departures";
                        }
                        else if(this.$data.query.status === 'A'){
                            this.locationNameSecond = " Station Arrivals";
                        }
                        else if(this.$data.query.status === 'T'){
                            this.locationName += " Departures";
                            this.locationNameSecond = "(Scheduled Tracks)";
                        }
                        else {
                            this.locationNameSecond = "Station Departures";
                        }
                    }
                    else{
                        if(this.pageType !== "rail"){
                            this.locationName += " Departures";
                        }
                        else if(this.$data.query.status === 'A'){
                            this.locationName += " Station Arrivals";
                        }
                        else if(this.$data.query.status === 'T'){
                            this.locationName += " Departures (Scheduled Tracks)";
                        }
                        else {
                            this.locationName += " Station Departures";
                        }
                    }
                }

                // need to make sure that I remove extraneous "station"
                const words = this.locationName.split(" ");
                let hasStation = false;
                let filteredLocationTokens = [];

                words.forEach(w => {
                    if(w.toLowerCase() === "station"){
                        if(hasStation){
                            return;
                        }
                        hasStation = true;
                    }
                    filteredLocationTokens.push(w);
                });

                this.locationName = filteredLocationTokens.join(" ");
            }
        }
    }
</script>

<style scoped>
    .stop {
        width: 90vw;
        margin-left: 5vw;
        height: calc(10 * var(--mvh));
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .location-banner {
        border-bottom: 2px solid var(--primary-color);
        border-top: 2px solid var(--primary-color);
        background-color: white;
        display: flex; 
        align-items: center; 
        justify-content: space-between;
        width: 100%;
        position: sticky;
        top: 0px;
        z-index: 100;
        height: calc(10 * var(--mvh));

        padding: 0 3rem;
    }

    .mobile .location-banner,
    .smallscreen .location-banner {
        position: fixed;
        justify-content: center;
        background-color: var(--primary-color);
        color: white;
        height: calc(10 * var(--mvh));
        /* padding: 0.5rem 1rem; */
        padding: 0.5rem 0rem;
        box-shadow: 0 0 10px var(--primary-color);

        display: grid;
        grid-template-columns: 20vw 80vw;

        z-index: 0;
    }

    .rotated.location-banner {
        position: relative;
        justify-content: center;
        background-color: var(--primary-color);
        color: white;
        height: calc(10 * var(--mvh));
        /* padding: 0.5rem 1rem; */
        padding: 0.5rem 0rem;
        box-shadow: 0 0 10px var(--primary-color);

        display: grid;
        grid-template-columns: 20vw 80vw;
        align-content: center;
    }
    
    .location-banner div,
    .location-banner div span {
        font-size: 1.7rem;
        font-weight: bold;
    }

    .mobile .location-banner div,
    .mobile .location-banner div span,
    .smallscreen .location-banner div,
    .smallscreen .location-banner div span {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .location-banner .large-name,
    .location-banner .large-name span {
        font-size: 1.3rem;
    }

    .mobile .large-name {
        padding-right: 1rem;
        text-align: center;
    }

    .mobile .location-banner .extra-large-name,
    .smallscreen .location-banner .extra-large-name {
        padding-right: 1rem;
        text-align: center;
        font-size: 1.2rem;
    }

    .location-centered {
        position: absolute; 
        width: 80vw; 
        height: calc(10 * var(--mvh));
        text-align:center; 
        left: 12vw;
    }

    .location-banner:not(.horizontal) {
        justify-content: center;
    }

    .njt-icon {
        /* background: url("../assets/njt_logo.svg"); */
        background: url("../assets/hug-icon-s.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .dark .njt-icon,
    .mobile .njt-icon,
    .smallscreen .njt-icon {
        /* background: url("../assets/njt_logo_white.svg"); */
        background: url("../assets/hug-icon-s-white.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .horizontal .njt-icon {
        /* height: min(calc(7 * var(--mvh)), 4vw);
        width: min(calc(7 * var(--mvh)), 4vw); */
        height: calc(8*var(--mvh));
        width: calc(6.5*var(--mvh));
        margin-left: -2rem;
        transform: scale(0.8);
    }

    .very-large-screen .horizontal .njt-icon {
        height: calc(8.1*var(--mvh));
    }

    .dark .sign-mode {
        background: var(--primary-color);
        color: white;
    }

    .mobile .njt-icon {
        margin-left: 1rem;
        /* width: 3.5rem;
        height: 3.5rem; */
        width: 2.9rem;
        height: 3.6rem;
    }
    
    .smallscreen .njt-icon {
        margin-left: 1rem;
        /* width: 3.5rem;
        height: 3.5rem; */
        width: calc(5.3 * var(--mvh));
        height: calc(6.6 * var(--mvh));
    }

    .rotated .njt-icon {
        /* background: url("../assets/njt_logo_white.svg"); */
        background: url("../assets/hug-icon-s-white.svg");
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 1rem;
        width: 2rem;
        height: 2.5rem;        
    }

    .back-button-container {
        transform: translateX(-4rem);
    }

    .larger-tablet.location-banner .njt-icon {
        position: relative;
    }

    .location-banner .njt-icon::after {
        content: "";
        position: absolute;
        background-color: white;
        height: 100%;
        width: 2px;
        top: 0%;
        right: -1rem;
    }

    .back-button {
        font-size: 0.85rem !important;
    }

    .very-large-location-name {
        position: absolute; 
        text-align:center; 
        left: 50%;
        transform: translate(-50%);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .very-large-location-name div {
        font-size: 1.9rem;
    }

</style>